// firebase.js or firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// import { getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, TwitterAuthProvider } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';
// import { getDatabase } from 'firebase/database';

const config = {
  apiKey: 'AIzaSyDaHjbcFurfO8zjTxytxYCPVOqxzbZmuFs',
  authDomain: 'uat-ttctrackingsystem.firebaseapp.com',
  projectId: 'uat-ttctrackingsystem',
  storageBucket: 'uat-ttctrackingsystem.appspot.com',
  messagingSenderId: '550594227026',
  appId: '1:550594227026:web:68fbcb266c28def125688b',
};

const app = initializeApp(config);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export { app, storage, db, auth };
