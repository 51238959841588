import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
}));

const SignupSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail is required')
    .email('Invalid email address'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  name: yup.string().required('Name is required'),
  mobile: yup.string().required('Phone Number is required'),
  type: yup.string().required('Type is required'),
  customer_id: yup.string().when('type', type => {
    if (type === 'customer') return yup.string().required('Customer id is required');
  }),
});

const defaultValues = {
  email: '',
  password: '',
  name: '',
  mobile: '',
  line_id: '',
  company: '',
  company_address: '',
  customer_id: '',
  sub_id: '',
  type: '',
};

const Action = ({ open, handleDialog, selectedUser, typeAction }) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { customers, sub } = useSelector(({ master }) => master);
  const classes = useStyles();
  const [isCustomer, setIsCustomer] = useState(false);
  const [customerId, setCustomerId] = useState(false);
  const [pending, setPending] = useState(false);
  const typeApproval = typeAction === 'approval';
  const typeEdit = typeAction === 'edit';
  const typeDelete = typeAction === 'delete';

  const handleCloseDialog = () => {
    handleDialog();
    reset();
  };

  const onSubmit = async ({ data }) => {
    setPending(true);
    try {
      if (authUser.type !== 'superadmin' && data.type === 'superadmin') return;
      if (data.type !== 'customer') delete data.customer_id;
      if (!data.sub_id) data.sub_id = '';
      data.name_lower = data.name.toLowerCase();
      data.email = data.email.toLowerCase();
      if (typeApproval) {
        await Api.approveUser(selectedUser);
        handleDialog();
        NotificationManager.success('User has been approved', 'Success');
      } else if (typeEdit) {
        await DatabaseService.updateRegistrationUser(selectedUser.id, data);
        handleDialog();
        NotificationManager.success('User has been updated', 'Success');
      } else if (typeDelete) {
        await DatabaseService.deleteRegistrationUser(selectedUser);
        handleDialog();
        NotificationManager.success('User has been deleted', 'Success');
      } else {
        const dup = await DatabaseService.checkUserDuplicate(data);
        if (!dup) {
          await DatabaseService.addRegistrationUser(data);
          handleDialog();
          NotificationManager.success('User has been created', 'Success');
        } else {
          NotificationManager.error('User already exists', 'Fail');
          setPending(false);
        }
      }
    } catch (err) {
      NotificationManager.error(err.message, 'Error');
      setPending(false);
    }
  };

  const fetchRowData = async () => {
    const row = selectedUser;
    if (row.type === 'customer') {
      setIsCustomer(true);
      setCustomerId(row.customer_id);
    }
    reset({
      email: row.email,
      password: row.password,
      name: row.name,
      mobile: row.mobile,
      line_id: row.line_id,
      company: row.company,
      company_address: row.company_address,
      type: row.type,
      customer_id: row.customer_id,
      sub_id: row.sub_id,
    });
  };

  useEffect(() => {
    if (selectedUser) fetchRowData();
  }, []);

  return (
    <Dialog open={open} onClose={handleCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {typeApproval
          ? 'Approval New User'
          : typeEdit
          ? 'Edit New User'
          : typeDelete
          ? 'Delete New User'
          : 'Create New User'}
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      variant="outlined"
                      error={errors.email && true}
                      helperText={errors.email?.message}
                      fullWidth
                      disabled={typeApproval || typeDelete || typeEdit}
                    />
                  )}
                  name="email"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      error={errors.password && true}
                      helperText={errors.password?.message}
                      disabled={typeApproval || typeDelete}
                    />
                  )}
                  name="password"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      variant="outlined"
                      fullWidth
                      error={errors.name && true}
                      helperText={errors.name?.message}
                      disabled={typeApproval || typeDelete}
                    />
                  )}
                  name="name"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      error={errors.mobile && true}
                      helperText={errors.mobile?.message}
                      disabled={typeApproval || typeDelete}
                    />
                  )}
                  name="mobile"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Line Id"
                      variant="outlined"
                      fullWidth
                      disabled={typeApproval || typeDelete}
                    />
                  )}
                  name="line_id"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Company"
                      fullWidth
                      variant="outlined"
                      disabled={typeApproval || typeDelete}
                    />
                  )}
                  name="company"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={4}
                      label="Address"
                      fullWidth
                      variant="outlined"
                      disabled={typeApproval || typeDelete}
                    />
                  )}
                  name="company_address"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item?.name);
                        item?.name === 'customer' ? setIsCustomer(true) : setIsCustomer(false);
                      }}
                      value={
                        authUser.type === 'superadmin'
                          ? TYPES_SUPERADMIN.find(v => v.name === value)
                          : TYPES.find(v => v.name === value) || {}
                      }
                      options={authUser.type === 'superadmin' ? TYPES_SUPERADMIN : TYPES}
                      getOptionLabel={item => (item.name ? item.name : '')}
                      disabled={typeApproval || typeDelete}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Type"
                          margin="normal"
                          variant="outlined"
                          error={errors.type && true}
                          helperText={errors.type?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {isCustomer && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      control={control}
                      name="customer_id"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item?.name);
                            setCustomerId(item?.name);
                            setValue('sub_id', '');
                          }}
                          value={customers.find(v => v.name.toString() === value) || {}}
                          options={customers}
                          getOptionLabel={item => item.name?.toString() ?? ''}
                          disabled={typeApproval || typeDelete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Customer Id"
                              margin="normal"
                              variant="outlined"
                              error={errors.customer_id && true}
                              helperText={errors.customer_id?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Controller
                      control={control}
                      name="sub_id"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item?.name);
                          }}
                          value={sub.find(v => v.name.toString() === value) || {}}
                          options={sub.filter(x => x.customer_id === customerId)}
                          getOptionLabel={item => item.name?.toString() ?? ''}
                          disabled={typeApproval || typeDelete || !customerId}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Sub"
                              margin="normal"
                              variant="outlined"
                              error={errors.sub_id && true}
                              helperText={errors.sub_id?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </GridContainer>
          </Box>

          {pending && <LinearProgress color="secondary" className={classes.linear} />}
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              {typeApproval && (
                <Button type="submit" variant="contained" color="primary" disabled={pending}>
                  Approve
                </Button>
              )}
              {typeEdit && (
                <EditButton type="submit" variant="contained" color="primary" disabled={pending}>
                  Update
                </EditButton>
              )}
              {typeDelete && (
                <DeleteButton type="submit" variant="contained" color="primary" disabled={pending}>
                  Delete
                </DeleteButton>
              )}
              {!typeApproval && !typeEdit && !typeDelete && (
                <Button type="submit" variant="contained" color="primary" disabled={pending}>
                  Create
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Action;

const TYPES = [
  { code: 'T01', name: 'driver' },
  { code: 'T02', name: 'customer' },
  { code: 'T03', name: 'frontadmin' },
  { code: 'T04', name: 'delivery' },
  { code: 'T05', name: 'accountant' },
  { code: 'T06', name: 'admin' },
  // { code: 'T07', name: 'superadmin' }
];

const TYPES_SUPERADMIN = [
  { code: 'T01', name: 'driver' },
  { code: 'T02', name: 'customer' },
  { code: 'T03', name: 'frontadmin' },
  { code: 'T04', name: 'delivery' },
  { code: 'T05', name: 'accountant' },
  { code: 'T06', name: 'admin' },
  { code: 'T07', name: 'superadmin' },
];
