import { db, storage } from '../firebase';
import { fetchError, fetchStart, fetchSuccess } from '../redux/actions';
import { fetchAllCustomers, fetchAllDrivers, fetchAllSub } from '../redux/actions/Master';
import {
  doc,
  collection,
  updateDoc,
  query,
  orderBy,
  onSnapshot,
  where,
  getDocs,
  getCountFromServer,
  getAggregateFromServer,
  sum,
  addDoc,
  getDoc,
  deleteDoc,
  limit,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
const DatabaseService = {
  updateOrder: async (id, data, authUser) => {
    try {
      ['height', 'length', 'cbm', 'packs', 'qty', 'weight', 'width'].forEach(field => {
        data[field] = parseFloat(data[field]);
      });

      ['po_number', 'container', 'tracking_number', 'customer_id'].forEach(field => {
        data[field] = data[field].toUpperCase();
      });

      data.type = data.productType;

      const statusUpdates = {
        'WH China': {
          update_arrived_wh_china: new Date(),
          update_arrived_wh_china_by: authUser.email,
          update_wh_china_remark: data.remark,
          packs_arrived_wh_china: data.packs,
        },
        'Closed Container': {
          update_closed_container: new Date(),
          update_closed_container_by: authUser.email,
          update_closed_container_remark: data.remark,
          packs_closed_container: data.packs,
        },
        'WH Thai': {
          update_arrived_wh_thai: new Date(),
          update_arrived_wh_thai_by: authUser.email,
          update_arrived_wh_thai_remark: data.remark,
          packs_arrived_wh_thai: data.packs,
        },
        'Delivering': {
          update_delivering: new Date(),
          update_delivering_by: authUser.email,
          update_delivering_remark: data.remark,
          packs_delivering: data.packs,
        },
        'Delivered': {
          update_delivered: new Date(),
          update_delivered_by: authUser.email,
          update_delivered_remark: data.remark,
          packs_delivered: data.packs,
        },
        'Cancel': {
          cancelled_by: authUser.email,
          cancelled_at: new Date(),
        },
      };

      if (statusUpdates[data.status]) {
        Object.assign(data, statusUpdates[data.status]);
      }

      const docRef = doc(db, 'orders', id);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Order not found');
      }

      const logRef = collection(db, 'update_history');
      await addDoc(logRef, {
        po_id: docSnap.id,
        po_number: docSnap.data().po_number,
        updated_at: new Date(),
        updated_by: authUser.email,
        old_data: docSnap.data(),
        new_data: data,
      });

      await updateDoc(docRef, data);
    } catch (error) {
      // console.error('Error updating order:', error);
      throw error;
    }
  },

  updateOrderStatusByProductNumber: async (id, data) => {
    try {
      const docRef = doc(db, 'orders', id);
      await updateDoc(docRef, data);
    } catch (error) {
      throw new Error(`Failed to update order status for document ID ${id}: ${error.message}`);
    }
  },

  // getAllDrivers: () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       let drivers = [];
  //       const ref = firestore.collection('users').where('type', '==', 'driver');
  //       ref.onSnapshot(async snapShot => {
  //         snapShot.forEach(doc => {
  //           if (doc.exists) {
  //             drivers.push(doc.data());
  //           }
  //         });
  //       });
  //       resolve(drivers);
  //     } catch (err) {
  //       reject(err);
  //     }
  //   });
  // },

  // getAllDriversDispatch: () => {
  //   return dispatch => {
  //     try {
  //       dispatch(fetchStart());
  //       let drivers = [];
  //       const ref = firestore.collection('users').where('type', '==', 'driver');
  //       ref.onSnapshot(snapShot => {
  //         snapShot.forEach(doc => {
  //           if (doc.exists) {
  //             drivers.push(doc.data());
  //           }
  //         });
  //       });
  //       dispatch(fetchAllDrivers(drivers));
  //       dispatch(fetchSuccess());
  //     } catch (err) {
  //       dispatch(fetchError(err));
  //     }
  //   };
  // },

  getAllDriversDispatch: () => {
    return dispatch => {
      dispatch(fetchStart());
      const driversRef = collection(db, 'drivers');

      onSnapshot(
        driversRef,
        snapshot => {
          const drivers = snapshot.docs.filter(doc => doc.exists()).map(doc => doc.data());

          dispatch(fetchAllDrivers(drivers));
          dispatch(fetchSuccess());
        },
        error => {
          dispatch(fetchError(error));
        },
      );
    };
  },

  getAllCustomers: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        const q = query(collection(db, 'customers'), orderBy('name', 'asc'));
        onSnapshot(
          q,
          querySnapshot => {
            const customers = querySnapshot.docs.map(doc => doc.data());
            dispatch(fetchAllCustomers(customers));
            dispatch(fetchSuccess());
          },
          error => {
            dispatch(fetchError(error));
          },
        );
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  getAllSub: () => {
    return async dispatch => {
      try {
        dispatch(fetchStart());
        let sub = [];
        const ref = query(collection(db, 'sub'), orderBy('name', 'asc'));
        onSnapshot(ref, snapShot => {
          sub = [];
          snapShot.forEach(doc => {
            if (doc.exists()) {
              sub.push(doc.data());
            }
          });
          dispatch(fetchAllSub(sub));
        });
        dispatch(fetchSuccess());
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  addCustmer: data => {
    return new Promise((resolve, reject) => {
      data.customer_price.map(item => {
        item.kg_ek_price = parseFloat(item.kg_ek_price);
        item.kg_sea_price = parseFloat(item.kg_sea_price);
        item.m3_ek_price = parseFloat(item.m3_ek_price);
        item.m3_sea_price = parseFloat(item.m3_sea_price);
      });

      const docRef = query(collection(db, 'customers'));
      docRef
        .add(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCustomer: async (id, data) => {
    try {
      if (data.customer_price) {
        data.customer_price = data.customer_price.map(item => ({
          ...item,
          kg_ek_price: parseFloat(item.kg_ek_price) || 0,
          kg_sea_price: parseFloat(item.kg_sea_price) || 0,
          m3_ek_price: parseFloat(item.m3_ek_price) || 0,
          m3_sea_price: parseFloat(item.m3_sea_price) || 0,
        }));
      }

      const docRef = doc(db, 'customers', id);
      await updateDoc(docRef, data);
    } catch (error) {
      // console.error('Error updating customer:', error);
      throw error;
    }
  },
  addSub: data => {
    return new Promise((resolve, reject) => {
      const docRef = query(collection(db, 'sub'));
      docRef
        .add(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateSub: async (id, data) => {
    try {
      const docRef = query(collection(db, 'sub', id));
      await updateDoc(docRef, data);
    } catch (error) {
      // console.error('Error updating subscription:', error);
      throw error;
    }
  },

  deleteSub: async ({ document }) => {
    try {
      // const docRef = doc(db, 'sub', document.id);
      const docRef = query(collection(db, 'sub', document.id));
      await deleteDoc(docRef);
    } catch (error) {
      // console.error('Error deleting subscription:', error);
      throw error;
    }
  },

  addDriver: data => {
    return new Promise((resolve, reject) => {
      data.status = 'Waiting Approve';
      // const docRef = firestore.collection('drivers');
      const docRef = query(collection(db, 'drivers'));
      docRef
        .add(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateDriver: (id, data) => {
    return new Promise((resolve, reject) => {
      // const docRef = doc(db, 'drivers', id);
      // const docRef = firestore.doc(`drivers/${id}`);
      const docRef = query(collection(db, 'drivers', id));
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteDriver: doc => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`drivers/${doc.id}`);
      const docRef = query(collection(db, 'drivers', doc.id));
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateUser: (id, data) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`users/${id}`);
      const docRef = query(collection(db, 'users', id));
      docRef
        .set(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addRegistrationUser: async ({ userData }) => {
    try {
      const registeredUsersCollection = collection(db, 'registered_users');
      const docRef = await addDoc(registeredUsersCollection, userData);
      return docRef;
    } catch (error) {
      throw error;
    }
  },

  checkUserDuplicate: async ({ data }) => {
    try {
      const registeredUsersRef = collection(db, 'registered_users');
      const registeredUsersQuery = query(registeredUsersRef, where('email', '==', data.email));
      const registeredUsersSnapshot = await getDocs(registeredUsersQuery);
      if (registeredUsersSnapshot.empty) {
        const usersRef = collection(db, 'users');
        const usersQuery = query(usersRef, where('email', '==', data.email));
        const usersSnapshot = await getDocs(usersQuery);
        if (usersSnapshot.empty) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      throw new Error('Error checking user duplicate: ' + error.message);
    }
  },

  updateRegistrationUser: (id, data) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`registered_users/${id}`);
      const docRef = query(collection((db, 'registered_users', id)));
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteRegistrationUser: doc => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`registered_users/${doc.id}`);
      const docRef = query(collection((db, 'registered_users', doc.id)));
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteCustomer: doc => {
    return new Promise((resolve, reject) => {
      const docRef = query(collection((db, 'customers', doc.id)));
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  uploadImages: async ({ files }) => {
    try {
      let result = { success: 0, error: 0 };
      let alerts = [];
      await Promise.all(
        files.map(async ({ file }) => {
          try {
            const filename = file.name
              .split('.')
              .slice(0, -1)
              .join('.');
            const ordersRef = collection(db, 'order');
            const q = query(ordersRef, where('po_number', '==', filename));
            const snapShot = await getDocs(q);
            if (!snapShot.empty) {
              const id = snapShot.docs[0].id;
              const storageRef = ref(storage, `/images/${filename}`);
              await uploadBytes(storageRef, file);
              const downloadURL = await getDownloadURL(storageRef);
              const docRef = doc(db, 'order', id);
              await updateDoc(docRef, {
                pictures_path: [downloadURL],
              });
              result.success++;
            } else {
              result.error++;
              alerts.push({ filename: file.name, message: 'Error: PO Number not found!' });
            }
          } catch (err) {
            result.error++;
            alerts.push({ filename: file.name, message: `Error: ${err.message}` });
          }
        }),
      );
      return { result: result, alerts: alerts };
    } catch (err) {
      throw new Error(err.message);
    }
  },

  getBillByNo: async ({ id }) => {
    try {
      const invoicesRef = collection(db, 'invoices');
      const q = query(invoicesRef, where('invoice_no', '==', id));
      const snapShot = await getDocs(q);
      if (!snapShot.empty) {
        let data = snapShot.docs[0].data();
        data.id = snapShot.docs[0].id;
        return data;
      } else {
        throw new Error('Invoice not found');
      }
    } catch (err) {
      throw new Error(`Error retrieving invoice: ${err.message}`);
    }
  },

  getDeliveryNoteByNo: async ({ id }) => {
    try {
      const deliveryNotesRef = collection(db, 'delivery_notes');
      const q = query(deliveryNotesRef, where('delivery_note_no', '==', id));
      const snapShot = await getDocs(q);
      if (!snapShot.empty) {
        let data = snapShot.docs[0].data();
        data.id = snapShot.docs[0].id;
        return data;
      } else {
        throw new Error('Delivery note not found');
      }
    } catch (err) {
      throw new Error(`Error retrieving delivery note: ${err.message}`);
    }
  },

  updateOrderBillCreated: async ({ id, data }) => {
    try {
      if (id) {
        const docRef = doc(db, 'orders', id);
        await updateDoc(docRef, data);
      } else {
        throw new Error('Invalid order ID');
      }
    } catch (err) {
      throw new Error(`Failed to update order: ${err.message}`);
    }
  },

  addInvoice: async ({ data, authUser }) => {
    try {
      data.calculation_type_upper = data.calculation_type.toUpperCase();
      let sub = '';
      let total = 0;
      data.productNumberList.forEach(({ item }) => {
        item.cbm = parseFloat(item.cbm);
        item.price = parseFloat(item.price);
        item.total = parseFloat(item.total);
        total += item.total;
        if (item.sub_customer && !sub.includes(item.sub_customer)) {
          sub += `${item.sub_customer}, `;
        }
      });

      data.total = total;
      data.sub = sub.replace(/,\s*$/, '');

      const periodRunning = `${data.invoice_date.getMonth()}-${data.invoice_date.getFullYear()}`;
      const invoiceNo = await DatabaseService.getNewInvoiceNumber({
        periodRunning,
        invoiceDate: data.invoice_date,
      });

      data.invoice_no = invoiceNo;
      data.period_running = periodRunning;
      data.created_by = authUser.name;
      data.status = 'Created';

      const invoicesRef = collection(db, 'invoices');
      const docRef = await addDoc(invoicesRef, data);

      await Promise.all(
        data.productNumberList.map(({ item }) =>
          DatabaseService.updateOrderBillCreated({
            id: item.id,
            data: {
              invoice_no: invoiceNo,
              created_bill_date: new Date(),
            },
          }),
        ),
      );

      return { invoiceNo: data.invoice_no };
    } catch (err) {
      throw new Error(`Failed to add invoice: ${err.message}`);
    }
  },

  getNewInvoiceNumber: async ({ periodRunning, invoiceDate }) => {
    try {
      const invoicesRef = collection(db, 'invoices');
      const q = query(invoicesRef, where('period_running', '==', periodRunning), orderBy('invoice_no', 'desc'), limit(1));
      const snapShot = await getDocs(q);
      const year = invoiceDate.getFullYear();
      const month = ('0' + (invoiceDate.getMonth() + 1)).slice(-2);
      if (!snapShot.empty) {
        const last3Digits = snapShot.docs[0].data().invoice_no.slice(-6);
        const newNo = (parseInt(last3Digits) + 1).toString().padStart(6, '0');
        const newInvoiceNo = `INV-${year}${month}${newNo}`;
        return newInvoiceNo;
      } else {
        return `INV-${year}${month}000001`;
      }
    } catch (err) {
      throw new Error(`Failed to generate new invoice number: ${err.message}`);
    }
  },

  // addDeliveryNote: ({ data, authUser }) => {
  //   return new Promise((resolve, reject) => {
  //     const docRef = firestore.collection('delivery_notes');
  //     const periodRunning = `${data.delivery_note_date.getMonth()}-${data.delivery_note_date.getFullYear()}`;

  //     DatabaseService.getNewDeliveryNoteNumber({
  //       periodRunning: periodRunning,
  //       invoiceDate: data.delivery_note_date,
  //     }).then(res => {
  //       const invoiceNo = res;
  //       data.delivery_note_no = invoiceNo;
  //       data.period_running = periodRunning;
  //       data.created_by = authUser.name;
  //       data.status = 'Created';

  //       let total = data.productNumberList.reduce(
  //         (a, v) => {
  //           a.packs += v.packs;
  //           a.weight += v.weight;
  //           a.cbm += v.cbm;
  //           return a;
  //         },
  //         {
  //           packs: 0,
  //           weight: 0,
  //           cbm: 0,
  //         },
  //       );

  //       data.total_pack = total.packs;
  //       data.total_weight = total.weight;
  //       data.total_cbm = total.cbm;

  //       docRef
  //         .add(data)
  //         .then(res => {
  //           data.productNumberList.map(item => {
  //             DatabaseService.updateOrderBillCreated({
  //               id: item.id,
  //               data: { created_delivery_note_date: new Date() },
  //             });
  //           });
  //           resolve({ deliveryNoteNo: data.delivery_note_no });
  //         })
  //         .catch(err => {
  //           reject(err);
  //         });
  //     });
  //   });
  // },
  // getNewDeliveryNoteNumber: ({ periodRunning, invoiceDate }) => {
  //   return new Promise((resolve, reject) => {
  //     const ref = firestore
  //       .collection('delivery_notes')
  //       .where('period_running', '==', periodRunning)
  //       .orderBy('delivery_note_no', 'desc');
  //     const year = invoiceDate.getFullYear();
  //     const month = ('0' + (invoiceDate.getMonth() + 1)).slice(-2);

  //     ref
  //       .get()
  //       .then(snapShot => {
  //         if (snapShot.size > 0) {
  //           const last3Digits = snapShot.docs[0].data().delivery_note_no.slice(-6);
  //           const newNo = (parseInt(last3Digits) + 1).toString();
  //           const newInvoiceNo = `DN-${year}${month}${newNo.padStart(6, '0')}`;

  //           resolve(newInvoiceNo);
  //         } else {
  //           resolve(`DN-${year}${month}000001`);
  //         }
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },

  fetchOrdersData: ({ authUser, status, filterSearch, searchText }) => {
    let ref = collection(db, 'orders');

    switch (authUser.type) {
      case 'customer':
        ref = query(ref, where('customer_id', '==', authUser.customerId));

        if (status !== 'All') {
          ref = query(ref, where('status', '==', status));
        }

        if (searchText && filterSearch) {
          ref = query(
            ref,
            where(filterSearch, '>=', searchText.toUpperCase()),
            where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
            orderBy(filterSearch, 'desc'),
          );
        } else {
          ref = query(ref, orderBy('po_number', 'desc'));
        }

        if (authUser.subId) {
          ref = query(ref, where('sub_customer', '==', authUser.subId));
        }

        break;
      case 'driver':
        ref = query(ref, where('assigned_driver', '==', authUser.name), where('status', 'in', ['Delivering', 'Delivered']));

        if (status !== 'All') {
          ref = query(ref, where('status', '==', status));
        }

        if (searchText && filterSearch) {
          ref = query(
            ref,
            where(filterSearch, '>=', searchText.toUpperCase()),
            where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
            orderBy(filterSearch, 'desc'),
          );
        } else {
          ref = query(ref, orderBy('po_number', 'desc'));
        }
        break;
      case 'admin':
      case 'superadmin':
      case 'frontadmin':
      case 'delivery':
      case 'accountant':
        if (status !== 'All') {
          ref = query(ref, where('status', '==', status));
        }
        if (searchText && filterSearch) {
          ref = query(
            ref,
            where(filterSearch, '>=', searchText.toUpperCase()),
            where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
            orderBy(filterSearch, 'desc'),
          );
        } else {
          ref = query(ref, orderBy('po_number', 'desc'));
        }
        break;
      default:
        break;
    }
    return ref;
  },

  fetchRemarkedOrdersData: ({ authUser, filterSearch, searchText }) => {
    let q = query(collection(db, 'orders'), where('remarked', '==', 1));
    switch (authUser.type) {
      case 'frontadmin':
      case 'delivery':
      case 'admin':
      case 'superadmin':
        if (searchText && filterSearch) {
          q = query(
            q,
            where(filterSearch, '>=', searchText.toUpperCase()),
            where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
            orderBy(filterSearch, 'desc'),
          );
        } else {
          q = query(q, orderBy('po_number', 'desc'));
        }
        break;
      default:
        break;
    }
    return q;
  },

  updateRemarkedByPo: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = doc(db, 'orders', id);
      // const docRef = firestore.doc(`orders/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchManagementData: ({ authUser, collections, filterSearch, searchText }) => {
    if (authUser.type === 'admin' || authUser.type === 'superadmin') {
      let q = collection(db, `${collections}`);
      const conditions = [];
      if (searchText && filterSearch) {
        conditions.push(where(filterSearch, '>=', searchText));
        conditions.push(where(filterSearch, '<=', searchText + '\uf8ff'));
        conditions.push(orderBy(filterSearch, 'desc'));
      } else {
        conditions.push(orderBy('name', 'asc'));
      }
      q = query(q, ...conditions);
      return q;
    }
    return null;
  },

  fetchDriverManagementData: ({ authUser, status, filterSearch, searchText }) => {
    if (authUser.type === 'delivery' || authUser.type === 'admin' || authUser.type === 'superadmin') {
      // let ref = firestore.collection(`/drivers`);
      let ref = collection(db, 'drivers');

      if (status !== 'All') {
        // ref = ref.where('status', '==', status);
      }

      // if (searchText && filterSearch) {
      //   ref = ref
      //     .where('name_lower', '>=', searchText)
      //     .where('name_lower', '<=', searchText + '\uf8ff')
      //     .orderBy('name_lower', 'desc');
      // } else {
      //   ref = ref.orderBy('name', 'asc');
      // }

      return ref;

      // if (status === 'All') {
      //   if (searchText && filterSearch) {
      //     return firestore
      //       .collection(`/drivers`)
      //       .where('name_lower', '>=', searchText)
      //       .where('name_lower', '<=', searchText + '\uf8ff')
      //       .orderBy('name_lower', 'desc');
      //   } else {
      //     return firestore.collection(`/drivers`).orderBy('name', 'asc');
      //   }
      // } else {
      //   if (searchText && filterSearch) {
      //     return firestore
      //       .collection(`/drivers`)
      //       .where('status', '==', status)
      //       .where('name_lower', '>=', searchText)
      //       .where('name_lower', '<=', searchText + '\uf8ff')
      //       .orderBy('name_lower', 'desc');
      //   } else {
      //     return firestore.collection(`/drivers`).where('status', '==', status).orderBy('name', 'asc');
      //   }
      // }
    }
  },

  fetchInvoicesData: ({ filterSearch, searchText, status, authUser }) => {
    let q = collection(db, 'invoices');
    const conditions = [];
    if (authUser.type === 'delivery') {
      conditions.push(where('status', 'in', ['Checking', 'Checked']));
    }
    if (status !== 'All') {
      conditions.push(where('status', '==', status));
    }
    if (searchText && filterSearch) {
      conditions.push(where(filterSearch, '>=', searchText.toUpperCase()));
      conditions.push(where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'));
      conditions.push(orderBy(filterSearch, 'desc'));
    } else {
      conditions.push(orderBy('invoice_no', 'desc'));
    }
    q = query(q, ...conditions);
    return q;
  },

  fetchDeliveryNotesData: ({ filterSearch, searchText, status }) => {
    let q = collection(db, 'delivery_notes');
    const conditions = [];
    if (status !== 'All') {
      conditions.push(where('status', '==', status));
    }
    if (searchText && filterSearch) {
      conditions.push(where(filterSearch, '>=', searchText.toUpperCase()));
      conditions.push(where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'));
      conditions.push(orderBy(filterSearch, 'desc'));
    } else {
      conditions.push(orderBy('delivery_note_no', 'desc'));
    }
    q = query(q, ...conditions);
    return q;
  },

  updateInvoiceStatus: ({ id, status, update_status_by, update_status_at, picturesPath, customUpdate, form }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`invoices/${id}`);
      const docRef = doc(db, 'invoices', id);
      docRef.get().then(async doc => {
        if (doc.exists) {
          let invoiceObj = {
            status: status,
            update_status_by: update_status_by,
            update_status_at: update_status_at,
            remark_customer: form?.remarkCustomer || doc.data()?.remark_customer || '',
            remark_internal: form?.remarkInternal || doc.data()?.remark_internal || '',
          };

          if (!customUpdate) {
            switch (status) {
              case 'Uploaded':
                invoiceObj = { ...invoiceObj, pictures_path: picturesPath };
                // invoiceObj = {
                //   status: status,
                //   update_status_by: update_status_by,
                //   update_status_at: update_status_at,
                //   pictures_path: picturesPath,
                //   remark_customer: form.remarkCustomer,
                //   remark_internal: form.remarkInternal,
                // }
                break;
              default:
            }
          }

          docRef
            .update(invoiceObj)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  addPaySlip: ({ id, update_status_by, update_status_at, picturesPath }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`invoices/${id}`);
      const docRef = doc(db, 'invoices', id);
      docRef.get().then(async doc => {
        if (doc.exists) {
          docRef
            .update({
              update_status_by: update_status_by,
              update_status_at: update_status_at,
              pictures_path: picturesPath,
            })
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  addDelivered: ({ id, update_status_by, update_status_at, picturesPath }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`delivery_notes/${id}`);
      const docRef = doc(db, 'delivery_notes', id);
      docRef.get().then(async doc => {
        if (doc.exists) {
          docRef
            .update({
              update_status_by: update_status_by,
              update_status_at: update_status_at,
              pictures_path: picturesPath,
            })
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  updateInvoiceDetail: ({ id, form, update_status_by, update_status_at }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`invoices/${id}`);
      const docRef = doc(db, 'invoices', id);

      docRef
        .update({
          status: form.status,
          remark_customer: form.remarkCustomer,
          remark_internal: form.remarkInternal,
          update_status_by: update_status_by,
          update_status_at: update_status_at,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  uploadSlip: ({ file, pictureName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadTask = storage.ref(`/slip/${pictureName}`).put(file);
        await uploadTask.then(res => {
          resolve(res);
        });
      } catch (err) {
        reject(err);
      }
    });
  },

  uploadDelivered: ({ file, pictureName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadTask = storage.ref(`/delivered/${pictureName}`).put(file);
        await uploadTask.then(res => {
          resolve(res);
        });
      } catch (err) {
        reject(err);
      }
    });
  },

  updateInvoiceConfirmStatus: ({ id, status, deliveredStatus, paidStatus, form }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`invoices/${id}`);
      const docRef = doc(db, 'invoices', id);
      docRef.get().then(async doc => {
        if (doc.exists) {
          let confirmDelivered = doc.data()?.confirm_delivered || deliveredStatus;
          let confirmPaid = doc.data()?.confirm_paid || paidStatus;

          if (confirmDelivered && confirmPaid) {
            status = 'Checked';
          } else if (confirmDelivered || confirmPaid) {
            status = 'Checking';
          }

          let obj = {
            status: status,
            confirm_delivered: confirmDelivered,
            confirm_paid: confirmPaid,
            remark_customer: form.remarkCustomer,
            remark_internal: form.remarkInternal,
          };

          docRef
            .update(obj)
            .then(() => {
              resolve(obj);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  cancelInvoiceStatus: ({ id, remark, cancel_by, cancel_at }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`invoices/${id}`);
      const docRef = doc(db, 'invoices', id);
      docRef
        .update({
          status: 'Cancel',
          remark: remark,
          cancel_by: cancel_by,
          cancel_at: cancel_at,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateDeliveryNoteStatus: ({
    id,
    status,
    update_status_by,
    update_status_at,
    picturesPath,
    driver,
    customUpdate,
    form,
  }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`delivery_notes/${id}`);
      const docRef = doc(db, 'delivery_notes', id);

      docRef.get().then(async doc => {
        if (doc.exists) {
          const data = doc.data();

          if (status === 'Delivering' || status === 'Confirm Delivered') {
            await Promise.all(
              data.productNumberList.map(item => {
                // console.log(item.id);
                // const orderRef = firestore.doc(`orders/${item.id}`);
                const orderRef = doc(db, 'orders', item.id);

                orderRef.get().then(doc => {
                  const data = doc.data();
                  // console.log('data', data);

                  if (
                    status === 'Delivering' &&
                    (data.status === 'WH China' || data.status === 'Closed Container' || data.status === 'WH Thai')
                  ) {
                    orderRef.update({
                      status: 'Delivering',
                      assigned_driver: driver,
                      update_delivering: update_status_at,
                      update_delivering_by: update_status_by,
                      packs_delivering: data.packs,
                    });
                  }

                  if (status === 'Confirm Delivered' && data.status === 'Delivering') {
                    orderRef.update({
                      status: 'Delivered',
                      update_delivered: update_status_at,
                      update_delivered_by: update_status_by,
                      packs_delivered: data.packs,
                    });
                  }
                });
              }),
            );
          }

          let deliveryNoteObj = {
            status: status,
            remark_customer: form?.remarkCustomer || doc.data()?.remark_customer || '',
            remark_internal: form?.remarkInternal || doc.data()?.remark_internal || '',
            update_status_at: update_status_at,
            update_status_by: update_status_by,
          };

          if (!customUpdate) {
            switch (status) {
              case 'Delivering':
                deliveryNoteObj = {
                  ...deliveryNoteObj,
                  driver: driver,
                  driver_original: data.driver,
                };
                break;
              case 'Delivered':
                deliveryNoteObj = {
                  ...deliveryNoteObj,
                  pictures_path: picturesPath,
                };
                break;
              default:
              //   deliveryNoteObj = {
              //     status: status,
              //     update_status_at: update_status_at,
              //     update_status_by: update_status_by,
              //   }
            }
          }

          docRef
            .update(deliveryNoteObj)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });

      // let deliveryNoteObj = {
      //   status: status,
      //   update_status_at: update_status_at,
      //   update_status_by: update_status_by,
      // };

      // if (!customUpdate) {
      //   switch (status) {
      //     case 'Delivering':
      //       deliveryNoteObj = {
      //         status: status,
      //         update_status_by: update_status_by,
      //         update_status_at: update_status_at,
      //         driver: driver
      //       }
      //       break;
      //     case 'Delivered':
      //       deliveryNoteObj = {
      //         status: status,
      //         update_status_by: update_status_by,
      //         update_status_at: update_status_at,
      //         pictures_path: picturesPath
      //       }
      //       break;
      //     // default:
      //     //   deliveryNoteObj = {
      //     //     status: status,
      //     //     update_status_at: update_status_at,
      //     //     update_status_by: update_status_by,
      //     //   }
      //   }
      // }

      // docRef
      //   .update(deliveryNoteObj)
      //   .then(res => {
      //     resolve(res);
      //   })
      //   .catch(err => {
      //     reject(err);
      //   });

      // if (status === 'Delivered' && picturesPath?.length > 0) {
      //   docRef
      //     .update({
      //       status: status,
      //       update_status_by: update_status_by,
      //       update_status_at: update_status_at,
      //       pictures_path: picturesPath
      //     })
      //     .then(res => {
      //       resolve(res);
      //     })
      //     .catch(err => {
      //       reject(err);
      //     });
      // } else {
      //   docRef
      //     .update({
      //       status: status,
      //       update_status_by: update_status_by,
      //     })
      //     .then(res => {
      //       resolve(res);
      //     })
      //     .catch(err => {
      //       reject(err);
      //     });
      // }
    });
  },

  cancelDeliveryNoteStatus: ({ id, remark, cancel_by, cancel_at }) => {
    return new Promise((resolve, reject) => {
      // const docRef = firestore.doc(`delivery_notes/${id}`);
      const docRef = doc(db, 'delivery_notes', id);
      docRef
        .update({
          status: 'Cancel',
          remark: remark,
          cancel_by: cancel_by,
          cancel_at: cancel_at,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchReceivableBalance: ({ filterSearch, searchText }) => {
    let ref = collection(db, 'invoices');
    let q = query(ref, where('status', '==', 'Printed'));
    if (searchText && filterSearch) {
      q = query(
        q,
        where(filterSearch, '>=', searchText.toUpperCase()),
        where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
        orderBy(filterSearch, 'desc'),
      );
    } else {
      q = query(q, orderBy('invoice_no', 'desc'));
    }
    return q;
  },
  fetchCountReceivableBalance: async ({ filterSearch, searchText }) => {
    const collectionRef = collection(db, 'invoices');
    let q = query(collectionRef, where('status', '==', 'Printed'));
    try {
      if (searchText && filterSearch) {
        q = query(
          q,
          where(filterSearch, '>=', searchText.toUpperCase()),
          where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
        );
      }
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;
      return count;
    } catch (error) {
      // console.error('Error counting documents:', error);
      return 0;
    }
  },
  fetchSumReceivableBalance: async ({ filterSearch, searchText }) => {
    const coll = collection(db, 'invoices');
    let q = query(coll, where('status', '==', 'Printed'));
    try {
      if (searchText && filterSearch) {
        q = query(
          q,
          where(filterSearch, '>=', searchText.toUpperCase()),
          where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
        );
      }
      const snapshot = await getAggregateFromServer(q, {
        total: sum('total'),
      });
      const sumItem = snapshot.data().total;
      return sumItem;
    } catch (error) {
      // console.log(error);
      return 0;
    }
  },
  fetchCollectionDailyReport: ({ filterSearch, searchText, day }) => {
    let ref = collection(db, 'invoices');
    let q = query(
      ref,
      where('status', 'in', ['Uploaded', 'Checking', 'Checked', 'Completed']),
      where('invoice_date', '>', day),
      where('invoice_date', '<', day),
    );
    if (searchText && filterSearch) {
      q = query(
        q,
        where(filterSearch, '>=', searchText.toUpperCase()),
        where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
        orderBy(filterSearch, 'desc'),
      );
    } else {
      q = query(q, orderBy('invoice_no', 'desc'));
    }
    return q;
  },

  fetchSumCollectionDailyReport: async ({ filterSearch, searchText, day }) => {
    let ref = collection(db, 'invoices');
    let q = query(
      ref,
      where('status', 'in', ['Uploaded', 'Checking', 'Checked', 'Completed']),
      where('invoice_date', '>', day),
      where('invoice_date', '<', day),
    );
    if (searchText && filterSearch) {
      q = query(
        q,
        where(filterSearch, '>=', searchText.toUpperCase()),
        where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
      );
    }
    const snapshot = await getAggregateFromServer(q, {
      total: sum('total'),
    });
    const sumItem = snapshot.data().total;
    // console.log(sumItem);
    return sumItem;
  },
  fetchCoutCollectionDailyReport: async ({ filterSearch, searchText }) => {
    return 0;
  },
  fetchCountCollectionDailyReport: async ({ filterSearch, searchText, day }) => {
    let ref = collection(db, 'invoices');
    let q = query(
      ref,
      where('status', 'in', ['Uploaded', 'Checking', 'Checked', 'Completed']),
      where('invoice_date', '>', day),
      where('invoice_date', '<', day),
    );
    if (searchText && filterSearch) {
      q = query(
        q,
        where(filterSearch, '>=', searchText.toUpperCase()),
        where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff'),
      );
    }
    const snapshot = await getCountFromServer(q);
    const count = snapshot.data().count;
    return count;
  },
};
export default DatabaseService;
