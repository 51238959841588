import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { firestore } from 'firebase';
import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
}));

const defaultValues = {
  packs: 0,
  remark: '',
};

const SignupSchema = yup.object().shape({
  // packs: yup.number().required('Pack is required').min(1, 'Pack must be greater than or equal to 1'),
});
const Action = ({ open, handleDialog, selectedUser, typeAction, showBy }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isCustomer, setIsCustomer] = useState(false);
  const [pending, setPending] = useState(false);
  const typeEdit = typeAction === 'edit';
  const typeDelete = typeAction === 'delete';
  const { authUser } = useSelector(({ auth }) => auth);
  const [pack, setPack] = useState(0);

  const handleCloseDialog = () => {
    handleDialog();
    reset();
  };

  const onSubmit = data => {
    setPending(true);
    data.status = 'WH Thai';
    // data.update_arrived_wh_thai = new Date();
    // data.update_arrived_wh_thai_by = authUser.email;
    data.update_arrived_wh_thai_remark = data.remark;
    data.remark = data.remark;

    if (showBy === 'container') {
      Api.updateOrdersByContainer(selectedUser.container, data)
        .then(res => {
          setPending(false);
          NotificationManager.success('Status has been updated', 'Success');
          handleDialog();
          reset();
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else {
      data.packs = parseFloat(data.packs);
      data.packs_arrived_wh_thai = parseFloat(data.packs);
      data.update_arrived_wh_thai = new Date();
      data.update_arrived_wh_thai_by = authUser.email;

      DatabaseService.updateOrderStatusByProductNumber(selectedUser.id, data)
        .then(() => {
          setPending(false);
          NotificationManager.success('Status has been updated', 'Success');
          handleDialog();
          reset();
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    }
  };

  const fetchRowData = async () => {
    const row = selectedUser;
    reset({
      packs: row.packs,
      remark: row.remark,
    });
    setPack(row.packs);
  };

  const handleDecrese = () => {
    setPack(pack - 1);
    setValue('packs', pack - 1);
  };

  const handleIncrese = () => {
    setPack(pack + 1);
    setValue('packs', pack + 1);
  };

  const handleChagePack = e => {
    const val = e.target.value;

    if (!isNaN(val)) {
      setPack(val);
      setValue('packs', val);
    } else {
      setPack(0);
      setValue('packs', 0);
    }
  };

  useEffect(() => {
    if (selectedUser) fetchRowData();
  }, []);

  return (
    <Dialog open={open} onClose={handleCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>Change Status</DialogTitle>
      <DialogContent>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          Comfirm to change status to 'WH Thai' for{' '}
          {showBy === 'container' ? selectedUser?.container : selectedUser?.po_number} ?
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              {showBy === 'productNumber' && (
                <Grid container style={{ marginTop: 20, marginBottom: 20 }} alignItems="center" justifyContent="center">
                  <Button color="secondary" onClick={handleDecrese}>
                    <RemoveIcon />
                  </Button>

                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="pack"
                        variant="outlined"
                        value={pack}
                        error={errors.packs && true}
                        helperText={errors.packs?.message}
                        disabled={typeDelete}
                        onChange={handleChagePack}
                      />
                    )}
                    name="packs"
                    control={control}
                  />
                  <Button color="secondary" onClick={handleIncrese}>
                    <AddIcon />
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={4}
                      label="Remark"
                      variant="outlined"
                      error={errors.company_address && true}
                      helperText={errors.company_address?.message}
                      disabled={typeDelete}
                      fullWidth
                    />
                  )}
                  name="remark"
                  control={control}
                />
              </Grid>
            </GridContainer>
          </Box>

          {pending && <LinearProgress color="secondary" className={classes.linear} />}
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button type="submit" variant="contained" color="primary" disabled={pending}>
                Confirm
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Action;
