/*import Firebase from './firebase';
import JWTAuth from './jwt';*/
// import BasicAuth from './Basic';
import Firebase from './firebase';

export const AuhMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  // basic: BasicAuth,
  firebase: Firebase,
};
