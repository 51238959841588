import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';

import DragNdrop from '../Components/dragNdrop';
import UploadStatus from '../Components/uploadStatus';
import AlertTable from '../Components/alertTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchFilter } from 'redux/actions/Search';
import { NotificationManager } from 'react-notifications';

// const breadcrumbs = [
//     { label: 'Home', link: '/' },
//     { label: 'Import Arrived WH China', isActive: true },
// ];

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const ImportArrivedWHChina = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const [uploadted, setUploadted] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [warningDatatable, setWarningDatatable] = useState([]);
  const [alert, setAlert] = useState({
    all: 0,
    success: 0,
    warning: 0,
    error: 0,
    alerts: [],
  });

  const { getRootProps, getInputProps, open, acceptedFiles, inputRef } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  });

  const handleUploadFiles = async () => {
    const version = 'รายการสินค้าเข้าโกดัง template version 1.4';
    // const startRow = 3;
    // let orders = [];

    setPending(true);

    acceptedFiles.map(file => {
      readXlsxFile(file).then(async rows => {
        // console.log(rows)
        // await Promise.all(rows.map(async (row, index) => {
        //     let hasData = row[col.date] && row[col.poNumber];
        //     if (index >= startRow && hasData) {
        //         const dup = orders.includes(row);
        //         if (!dup) orders.push(orderObj(row));
        //     }
        // }));

        if (rows[0][0] === version) {
          importJsonArrivedWhChina(rows);
        } else {
          setErrorUpload(true);
          setPending(false);
        }
      });
    });
  };

  const importJsonArrivedWhChina = orders => {
    Api.addArrivedWhChina(orders)
      .then(async res => {
        const resAlert = await res.json();
        setAlert(resAlert);

        setUploadted(true);
        setPending(false);
        acceptedFiles.splice(0, acceptedFiles.length);
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  useEffect(() => {
    dispatch(fetchFilter([]));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={4} container justifyContent="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.importArrivedWhChina" />
        </Typography>
      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={6} container justifyContent="flex-end">
        <a
          href="https://drive.google.com/file/d/1IFOzG7whXINpz5dW8YblZJpxK1vLOaPx/view?usp=sharing"
          target="_blank"
          rel="noreferrer">
          Download Template for Import Arrived WH China
        </a>
      </Grid>

      <Grid item xs={12}>
        <Box>
          {uploadted ? (
            <UploadStatus setUploadted={setUploadted} alert={alert} />
          ) : (
            <DragNdrop
              handleUploadFiles={handleUploadFiles}
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              pending={pending}
              errorUpload={errorUpload}
            />
          )}

          {uploadted && alert.alerts.length > 0 && !pending && (
            <div style={{ marginTop: 20 }}>
              <AlertTable alerts={alert.alerts} />
            </div>
          )}
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default ImportArrivedWHChina;

// const col = {
//     no: 0,
//     date: 1,
//     customer: 2,
//     sub_customer: 3,
//     poNumber: 4,
//     description: 5,
//     qty: 6,
//     weight: 7,
//     port: 8,
//     trackingNumber: 9,
//     length: 10,
//     width: 11,
//     height: 12,
//     pack: 13,
//     cbm: 14,
//     productType: 15,
//     transportationType: 16,
// };

// const orderObj = (row) => {
//     return {
//         assigned_driver: '',
//         assigned_driver_email: '',
//         container: '',
//         date: new Date(row[col.date]),
//         customer_id: row[col.customer]?.toString() ?? '',
//         sub_customer: row[col.sub_customer]?.toString() ?? '',
//         po_number: row[col.poNumber],
//         description: row[col.description]?.toString() ?? '',
//         weight: parseFloat(row[col.weight]),
//         port: row[col.port]?.toString() ?? '',
//         tracking_number: row[col.trackingNumber]?.toString() ?? '',
//         length: parseFloat(row[col.length]),
//         width: parseFloat(row[col.width]),
//         height: parseFloat(row[col.height]),
//         packs: parseFloat(row[col.pack]),
//         packs_arrived_wh_china: parseFloat(row[col.pack]),
//         packs_closed_container: 0.0,
//         packs_arrived_wh_thai: 0.0,
//         cbm: parseFloat(row[col.cbm]),
//         productType: row[col.productType]?.toString() ?? '',
//         transportationType: row[col.transportationType]?.toString() ?? '',
//         qty: parseFloat(row[col.qty]),
//         qty_arrived_wh_china: parseFloat(row[col.qty]),
//         qty_closed_container: 0.0,
//         qty_arrived_wh_thai: 0.0,
//         remark: '',
//         unit: '',
//         update_arrived_wh_china: new Date(),
//         update_arrived_wh_thai: new Date(1900, 0, 1, 0, 0, 0),
//         update_arrived_wh_thai_by: '',
//         update_closed_container: new Date(1900, 0, 1, 0, 0, 0),
//         update_closed_container_by: '',
//         update_delivered: new Date(1900, 0, 1, 0, 0, 0),
//         update_delivered_by: '',
//         update_delivering: new Date(1900, 0, 1, 0, 0, 0),
//         update_delivering_by: '',
//         img_url: '',
//         status: 'WH China',
//         created_at: new Date(),
//     }
// }

// const warningObj = (row) => {
//     return {
//         productNumber: row[col.poNumber],
//         container: '',
//         packs: row[col.pack],
//         cbm: row[col.cbm],
//         date: new Date(row[col.date]).toDateString(),
//         customer_id: row[col.customer],
//         sub_customer: row[col.sub_customer],
//         status: 'WH China',
//         importResult: 'Warning Updated',
//     }
// }
