import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';

import DragNdrop from '../Components/dragNdrop';
import UploadStatus from '../Components/uploadStatus';
import AlertTable from '../Components/alertTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchFilter } from 'redux/actions/Search';
import { NotificationManager } from 'react-notifications';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Import Closed Container', isActive: true },
];

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const ImportClosedContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const [uploadted, setUploadted] = useState(false);
  const [warningDatatable, setWarningDatatable] = useState([]);
  const [errorUpload, setErrorUpload] = useState(false);
  const [alert, setAlert] = useState({
    all: 0,
    success: 0,
    warning: 0,
    error: 0,
    alerts: [],
  });

  const { getRootProps, getInputProps, open, acceptedFiles, inputRef } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  });

  const handleUploadFiles = async () => {
    const version = 'รายการสินค้าปิดตู้ template version 1.3';
    // const startRow = 3;
    // let orders = [];

    setPending(true);

    acceptedFiles.map(file => {
      readXlsxFile(file).then(async rows => {
        if (rows[0][0] === version) {
          ImportJsonClosedContainer(rows);
        } else {
          setErrorUpload(true);
          setPending(false);
        }
      });
    });
  };

  const ImportJsonClosedContainer = orders => {
    Api.addClosedContainer(orders)
      .then(async res => {
        const resAlert = await res.json();

        setAlert(resAlert);
        setUploadted(true);
        setPending(false);
        acceptedFiles.splice(0, acceptedFiles.length);
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  useEffect(() => {
    dispatch(fetchFilter([]));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={4} container justifyContent="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.importClosedContainer" />
        </Typography>
      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={6} container justifyContent="flex-end">
        <a
          href="https://drive.google.com/file/d/1OkjkuNwdSNKj-V-i4ndXSQG-G2eLRZut/view?usp=sharing"
          target="_blank"
          rel="noreferrer">
          Download Template for Import Closed Container
        </a>
      </Grid>
      <Grid item xs={12}>
        <Box>
          {/* <IntlMessages id="pages.samplePage.description" /> */}
          {uploadted ? (
            <UploadStatus setUploadted={setUploadted} alert={alert} />
          ) : (
            <DragNdrop
              handleUploadFiles={handleUploadFiles}
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              pending={pending}
              errorUpload={errorUpload}
            />
          )}
          {uploadted && alert.alerts.length > 0 && !pending && (
            <div style={{ marginTop: 20 }}>
              <AlertTable alerts={alert.alerts} />
            </div>
          )}
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default ImportClosedContainer;
