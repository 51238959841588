import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { onAuthStateChanged, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc, getDocs, setDoc, collection, query, where, limit } from 'firebase/firestore';
import { db, auth } from '../../../firebase';
// import { firestore } from 'firebase';
const useStyles = makeStyles(theme => ({
  iconBtn: {
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 6,
    },
  },
}));

const SocialMediaIcons = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const signInUserWithGoogle = () => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(auth.googleAuthProvider)
        .then(data => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(data.user));
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const signInUserWithGithub = () => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(auth.githubAuthProvider)
        .then(data => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(data.user));
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const signInUserWithFacebook = () => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(auth.facebookAuthProvider)
        .then(data => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(data.user));
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const signInUserWithTwitter = () => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(auth.twitterAuthProvider)
        .then(data => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(data.user));
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton className={classes.iconBtn} onClick={signInUserWithFacebook}>
        <FacebookIcon />
      </IconButton>
      <IconButton className={classes.iconBtn} onClick={signInUserWithTwitter}>
        <TwitterIcon />
      </IconButton>
      <IconButton className={classes.iconBtn} onClick={signInUserWithGoogle}>
        <FacebookIcon />
      </IconButton>
      <IconButton className={classes.iconBtn} onClick={signInUserWithGithub}>
        <GitHubIcon />
      </IconButton>
    </Box>
  );
};

const Firebase = {
  onRegister: ({ email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.user));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogin: ({ email, password }) => {
    return async dispatch => {
      try {
        dispatch(fetchStart());
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        dispatch(fetchSuccess());
        dispatch(setAuthUser(userCredential));
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .signOut()
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(null));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: () => {
    return async dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(false));
      try {
        onAuthStateChanged(auth, async authUser => {
          dispatch(fetchSuccess());
          if (authUser) {
            try {
              const token = await authUser.getIdToken();
              let id = authUser.uid;
              console.log('UID: ', id);
              const userDocRef = doc(db, 'users', id);
              const userDocSnap = await getDoc(userDocRef);
              if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                // Check if userData contains any document reference fields.
                dispatch(
                  setAuthUser({
                    uid: authUser.uid,
                    displayName: authUser.displayName,
                    email: authUser.email,
                    photoURL: authUser.photoURL,
                    token: token,
                    refreshToken: authUser.refreshToken,
                    name: userData.name,
                    type: userData.type,
                    customerId: userData.customer_id,
                    subId: userData.sub_id,
                  }),
                );
                localStorage.setItem('token', token);
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
              // dispatch(updateLoadUser(true));
            }
          } else {
            // dispatch(updateLoadUser(true));
          }
        });
      } catch (error) {
        console.error('Error with authentication state change:', error);
        // dispatch(updateLoadUser(true));
        // dispatch(fetchError(error.message));
      }
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },

  getSocialMediaIcons: () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box component="p" fontSize={{ xs: 13, sm: 16 }}>
          Or Login with
        </Box>
        <SocialMediaIcons />
      </Box>
    );
  },
};

export default Firebase;
