import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';


// const RBAC = ({ allowedRoles, children }) => {
//     const { authUser } = useSelector(({ auth }) => auth);

//     let access = false;

//     if (!access)
//         access = authUser && authUser.type && allowedRoles.includes(authUser.type);

//     return access && children;
// };

// RBAC.propTypes = {
//     allowedRoles: PropTypes.arrayOf(PropTypes.string),
//     children: PropTypes.element
// };

// export default RBAC;



//Return bool
const RBAC = ({ allowedRoles }) => {
    const { authUser } = useSelector(({ auth }) => auth);

    let access = false;

    if (!access)
        access = authUser && authUser.type && allowedRoles.includes(authUser.type);

    return access;
};

RBAC.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

export default RBAC;
