import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import { Add, AssignmentTurnedIn, Edit, Delete, NavigateNext, NavigateBefore } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatabaseService from '../../../../services/database';
import memoize from 'memoize-one';
import { db } from '../../../../firebase';
import { doc } from 'firebase/firestore';
import Api from 'services/api.service';
import { usePagination } from 'use-pagination-firestore';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { fetchFilter } from 'redux/actions/Search';
import { NotificationManager } from 'react-notifications';
import Checkbox from '@material-ui/core/Checkbox';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Sample Page', isActive: true },
];

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = [
  {
    name: 'Product Number',
    selector: 'po_number',
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
  },
  {
    name: 'Packs',
    selector: 'packs',
    sortable: true,
    right: true,
  },
  {
    name: 'CBM',
    selector: 'cbm',
    sortable: true,
    right: true,
    cell: row => row.cbm.toFixed(4),
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    cell: row => moment(row.date.toDate()).format('DD/MM/YYYY'),
    width: '120px',
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
  },
  {
    name: 'Qty',
    selector: 'qty',
    sortable: true,
    right: true,
  },
  {
    name: 'Driver',
    selector: 'driver',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'Remark',
    selector: 'remark',
    sortable: true,
  },
];

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 25,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const UpdateRemark = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [remark, setRemark] = useState();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    container: '',
    poNumber: '',
    trackingNumber: '',
  });
  const [select, setSelect] = useState('');
  const [pending, setPending] = useState(false);
  const [checkRemarked, setCheckRemarked] = useState(false);

  const handleRadioChange = event => {
    setRemark('');
    setFilter({ container: '', poNumber: '', trackingNumber: '' });
    setData([]);
    setSelect(event.target.value);
  };

  const handleSetFilter = e => {
    switch (select) {
      case 'container':
        setFilter({
          container: e.target.value,
          poNumber: '',
          trackingNumber: '',
        });
        break;
      case 'poNumber':
        setFilter({
          container: '',
          poNumber: e.target.value,
          trackingNumber: '',
        });
        break;
      case 'trackingNumber':
        setFilter({
          container: '',
          poNumber: '',
          trackingNumber: e.target.value,
        });
        break;
      default:
    }
  };

  const handleSearchOrder = () => {
    if (
      (select === 'container' && filter.container) ||
      (select === 'poNumber' && filter.poNumber) ||
      (select === 'trackingNumber' && filter.trackingNumber) ||
      select === 'waitClearRemarked'
    ) {
      setPending(true);

      const ordersRef = collection(db, 'orders');
      let q = query(ordersRef);

      switch (select) {
        case 'container':
          q = query(ordersRef, where('container', 'in', [filter.container, ` ${filter.container}`]));
          break;
        case 'poNumber':
          q = query(ordersRef, where('po_number', '==', filter.poNumber));
          break;
        case 'trackingNumber':
          q = query(ordersRef, where('tracking_number', '==', filter.trackingNumber));
          break;
        case 'waitClearRemarked':
          q = query(ordersRef, where('remarked', '==', 1));
          break;
        default:
      }

      if (checkRemarked) {
        q = query(q, where('remarked', '==', 1));
      }
      onSnapshot(q, (snapShot) => {
        const tempDataArray = snapShot.docs
          .filter(doc => doc.exists())
          .map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

        setData(tempDataArray);
        setPending(false);
      });
    } else {
      setData([]);
    }
  };

  const handleUpdateOrder = () => {
    setPending(true);

    Api.updateOrdersRemark(data, remark, 1)
      .then(() => {
        setPending(false);
        NotificationManager.success('Remark has been updated', 'Success');
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  const handleClearRemark = () => {
    setPending(true);

    Api.updateOrdersRemark(data, remark, 0)
      .then(() => {
        setPending(false);
        NotificationManager.success('Remark has been cleared', 'Success');
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  const handleCheckRemarked = e => {
    setCheckRemarked(e.target.checked);
  };

  useEffect(() => {
    dispatch(fetchFilter([]));
  }, []);

  return (
    <GridContainer>
      <Grid item sm={4} xs={12}>
        <Typography variant="h2">
          <IntlMessages id="page.updateRemark" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <RadioGroup aria-label="quiz" name="quiz" value={select} onChange={handleRadioChange}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FormControlLabel value="container" control={<Radio />} label="Update remark by Container: " />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="standard-basic"
                          value={filter.container}
                          variant="outlined"
                          onChange={e => handleSetFilter(e)}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FormControlLabel value="poNumber" control={<Radio />} label="Update remark by PO Number: " />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="standard-basic"
                          value={filter.poNumber}
                          variant="outlined"
                          onChange={e => handleSetFilter(e)}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FormControlLabel
                          value="trackingNumber"
                          control={<Radio />}
                          label="Update remark by Tracking NO: "
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="standard-basic"
                          variant="outlined"
                          value={filter.trackingNumber}
                          onChange={e => handleSetFilter(e)}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FormControlLabel value="waitClearRemarked" control={<Radio />} label="Wait Clear Remarked" />
                      </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FormControlLabel
                          control={<Checkbox name="checked" />}
                          name="checked"
                          onChange={e => handleCheckRemarked(e)}
                          label="Remarked"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  <br />
                  <Button type="submit" variant="outlined" className={classes.button} onClick={handleSearchOrder}>
                    Search Order
                  </Button>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Update Remark"
                  multiline
                  minRows={4}
                  fullWidth
                  variant="outlined"
                  value={remark}
                  onChange={e => setRemark(e.target.value)}
                  disabled={data.length === 0}
                />

                <Button
                  style={{ marginTop: '20px' }}
                  type="submit"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleUpdateOrder}
                  disabled={data.length === 0}>
                  Update Remark
                </Button>

                <Button
                  style={{ marginTop: '20px', marginLeft: '20px' }}
                  type="submit"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleClearRemark}
                  disabled={data.length === 0}>
                  Clear Remark
                </Button>
              </Grid>
            </Grid>

            {pending && (
              <div style={{ marginTop: 25 }}>
                <LinearIndeterminate />
              </div>
            )}

            {data.length > 0 && (
              <Grid item xs={12} style={{ marginTop: 25 }}>
                <Box>
                  <DataTable
                    noHeader
                    title="Update Delivering"
                    columns={columns}
                    data={data}
                    // progressPending={isLoading}
                    progressComponent={<LinearIndeterminate />}
                    customStyles={customStyles}
                  />
                </Box>
              </Grid>
            )}
          </Card>
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default UpdateRemark;
