import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import { Add, Edit, Delete, NavigateNext, NavigateBefore, Print, Visibility } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatabaseService from '../../../../services/database';
import memoize from 'memoize-one';
import { usePagination } from 'use-pagination-firestore';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';
import CreateDeliveryNote from './Dialog/createDeliveryNote';
import CreateDeliveryNoteByContainer from './Dialog/createDeliveryNoteByContainer';
import PrintByContainerDialog from './Dialog/printByContainerDialog';
import PrintDeliveryNote from './Print/print';
import NumberWithCommas from 'helpers/NumberWithCommas';
import Action from './Action/action';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import differenceBy from 'lodash/differenceBy';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = memoize((handlePrint, handleViewDetail, handleEdit, authUser) => [
  {
    cell: row =>
      (row.status !== 'Cancel' || authUser.type === 'superadmin') && (
        <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />
      ),
    // cell: row => <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row => <Visibility onClick={() => handleViewDetail(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row => <Print onClick={() => handlePrint(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Delivery Note No.',
    selector: 'delivery_note_no',
    sortable: true,
    width: '170px',
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'Delivery Note Date',
    selector: 'delivery_note_date',
    sortable: true,
    cell: row => moment(row.delivery_note_date.toDate()).format('DD/MM/YYYY'),
    width: '125px',
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
    width: '360px',
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
  },
  {
    name: 'Sub',
    selector: 'sub',
    sortable: true,
  },
  {
    name: 'Total Pack',
    selector: 'total_pack',
    sortable: true,
    cell: row => NumberWithCommas(row.total_pack),
  },
  {
    name: 'Total Weight',
    selector: 'total_weight',
    sortable: true,
    cell: row => NumberWithCommas(row.total_weight.toFixed(2)),
  },
  {
    name: 'Total CBM',
    selector: 'total_cbm',
    sortable: true,
    cell: row => row.total_cbm.toFixed(4),
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const Delivery = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { customers } = useSelector(({ master }) => master);
  const { filterSearch } = useSelector(({ search }) => search);
  const { searchText } = useSelector(({ search }) => search);
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openCreateContainer, setOpenCreateContainer] = useState(false);
  const [openPrintContainer, setOpenPrintContainer] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openPrintSelected, setOpenPrintSelected] = useState(false);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [typeAction, setTypeAction] = useState();
  const [showBy, setShowBy] = useState('container');
  const [status, setStatus] = useState('All');
  const [drivers, setDrivers] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    DatabaseService.fetchDeliveryNotesData({ filterSearch: filterSearch, searchText: searchText, status: status }),
    {
      limit: 20,
    },
  );

  const handleEdit = row => {
    setTypeAction('Edit');
    setSelectedInvoice(row);
    setOpenAction(true);
  };

  const handleViewDetail = row => {
    setTypeAction('Detail');
    setSelectedInvoice(row);
    setOpenAction(true);
  };

  const handlePrint = row => {
    setSelectedInvoice(row);
    setOpenDetail(true);
  };

  const fetchDrivers = () => {
    dispatch(DatabaseService.getAllDriversDispatch());
  };

  const printByContainer = () => {
    setOpenPrintContainer(true);
  };

  // const handleDialogAction = () => {
  //     setOpenAction(false);
  // }

  const handleChangeStatus = (event, value) => {
    if (value?.name) setStatus(value.name);
  };

  const handleSelectedChange = ({ selectedRows }) => {
    setItemSelected(selectedRows);
  };

  const printBySelected = () => {
    setOpenPrintSelected(true);
  };

  // const handleOpenDialog = (popupState) => {
  //     popupState.close();
  //     setOpenCreate(true);
  // }

  useEffect(() => {
    if (customerId) setFilterData(data.filter(e => e.customer_id === customerId));
    else setFilterData(data);
  }, [customerId]);

  useEffect(() => {
    fetchDrivers();
    dispatch(fetchFilter([]));
  }, []);

  useEffect(() => {
    dispatch(fetchSearch(''));
    dispatch(
      fetchFilter([
        { key: 'delivery_note_no', name: 'Delivery Note No.' },
        { key: 'container', name: 'Container' },
        { key: 'customer_id', name: 'Customer' },
      ]),
    );
  }, []);

  const contextActions = React.useMemo(() => {
    return (
      <Button variant="contained" color="secondary" onClick={printBySelected}>
        <Print /> Print
      </Button>
    );
  }, [itemSelected, toggleCleared]);

  return (
    <GridContainer>
      <Grid item xs={12} sm={2} md={2} container justifyContent="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.delivery" />
        </Typography>
      </Grid>

      <Grid item xs={12} sm={2} md={3} container justifyContent="flex-end">
        <Autocomplete
          closeIcon={false}
          size="small"
          options={STATUS}
          getOptionLabel={option => option.name}
          style={{ width: 200 }}
          value={STATUS.find(v => v.name === status) || {}}
          onChange={handleChangeStatus}
          renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
        />
      </Grid>

      <Grid item xs={12} sm={8} md={7} container justifyContent="flex-end">
        <Grid item>
          <Button style={{ marginLeft: 10 }} variant="contained" color="secondary" onClick={printByContainer}>
            <Print /> Print by Container
          </Button>
        </Grid>

        <Grid item>
          {/* <Button style={{ marginLeft: 10 }} variant="contained" color="secondary" onClick={() => setOpenCreate(true)}>
                        <Add /> Create Delivery Note
                    </Button> */}

          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                <Button style={{ marginLeft: 10 }} variant="contained" color="secondary" {...bindTrigger(popupState)}>
                  <Add /> Create Delivery Note
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      history.push('/billing-delivery/create-delivery-note-by-container');
                    }}>
                    Create by Container
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      setOpenCreate(true);
                    }}>
                    Create by Customer
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            {/* {itemSelected.length > 0 && <Grid item>
                            <Button variant="contained" color="secondary" onClick={printBySelected}>
                                <Print /> Print
                            </Button>
                        </Grid>
                        } */}

            <DataTable
              noHeader={itemSelected.length > 0 ? false : true}
              title="Delivering"
              columns={columns(handlePrint, handleViewDetail, handleEdit, authUser)}
              data={items}
              progressPending={isLoading}
              progressComponent={<LinearIndeterminate />}
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleSelectedChange}
              contextActions={contextActions}
            />
          </Card>
          <Grid container justifyContent="flex-end" spacing={5} style={{ marginTop: 5 }}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={getPrev} disabled={isStart}>
                <NavigateBefore /> Previous
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="secondary" onClick={getNext} disabled={isEnd}>
                Next <NavigateNext />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {openCreate && <CreateDeliveryNote open={openCreate} setOpen={setOpenCreate} />}
      {openDetail && (
        <PrintDeliveryNote
          invoices={[selectedInvoice]}
          open={openDetail}
          setOpenDetail={setOpenDetail}
          printBy={'DeliveryNote'}
        />
      )}
      {openPrintContainer && <PrintByContainerDialog open={openPrintContainer} setOpen={setOpenPrintContainer} />}
      {openAction && (
        <Action open={openAction} setOpenAction={setOpenAction} selectedRow={selectedInvoice} typeAction={typeAction} />
      )}
      {openPrintSelected && (
        <PrintDeliveryNote
          invoices={itemSelected}
          open={openPrintSelected}
          setOpenDetail={setOpenPrintSelected}
          printBy={'Selected'}
        />
      )}
    </GridContainer>
  );
};

export default Delivery;

const STATUS = [
  { name: 'All' },
  { name: 'Created' },
  { name: 'Printed' },
  { name: 'Delivering' },
  { name: 'Delivered' },
  { name: 'Confirm Delivered' },
  { name: 'Cancel' },
];
