import { FETCH_FILTER, FETCH_SEARCH, FILTER_SEARCH } from '../../@jumbo/constants/ActionTypes';

export const fetchFilter = text => {
    return dispatch => {
        dispatch({
            type: FETCH_FILTER,
            payload: text,
        });
    };
};

export const fetchSearch = text => {
    return dispatch => {
        dispatch({
            type: FETCH_SEARCH,
            payload: text,
        });
    };
};

export const filterSearch = text => {
    return dispatch => {
        dispatch({
            type: FILTER_SEARCH,
            payload: text,
        });
    };
};