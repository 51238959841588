import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import { Add, AssignmentTurnedIn, Edit, Delete, NavigateNext, NavigateBefore, Print, Visibility } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatabaseService from '../../../../services/database';
import memoize from 'memoize-one';
import Api from 'services/api.service';
import { usePagination } from 'use-pagination-firestore';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';
import PrintInvoice from './Print/print';
import CreateBill from './Dialog/createBill';
import NumberWithCommas from '../../../../helpers/NumberWithCommas';
import Action from './Action/action';
import RBAC from '../../../../helpers/Rbac';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = memoize((handlePrint, handleViewDetail, handleEdit, checkPermission) => [
  {
    // cell: row => checkPermission(row.status) && row.status !== 'Cancel' && <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    cell: row => checkPermission(row.status) && <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row => <Visibility onClick={() => handleViewDetail(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row =>
      RBAC({ allowedRoles: ['superadmin', 'frontadmin'] }) && (
        <Print onClick={() => handlePrint(row)} style={{ cursor: 'pointer' }} />
      ),
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Invoice No.',
    selector: 'invoice_no',
    sortable: true,
    width: '170px',
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'Invoice Date',
    selector: 'invoice_date',
    sortable: true,
    width: '125px',
    cell: row => moment(row.invoice_date.toDate()).format('DD/MM/YYYY'),
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
    width: '360px',
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Calculation Type',
    selector: 'calculation_type',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Warehouse',
    selector: 'warehouse',
    sortable: true,
    width: '120px',
  },
  {
    name: 'Grand Total',
    selector: 'grandTotal',
    sortable: true,
    width: '130px',
    cell: row => NumberWithCommas(row.total.toFixed(2)),
  },
  {
    name: 'Delivery Date',
    selector: 'delivery_date',
    sortable: true,
    width: '200px',
    cell: row => moment(row.delivery_date.toDate()).format('DD/MM/YYYY'),
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const Bill = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { customers } = useSelector(({ master }) => master);
  const { filterSearch } = useSelector(({ search }) => search);
  const { searchText } = useSelector(({ search }) => search);
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [typeAction, setTypeAction] = useState();
  const [showBy, setShowBy] = useState('container');
  const [status, setStatus] = useState('All');
  const [drivers, setDrivers] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  // const [list, setList] = useState([]);

  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    DatabaseService.fetchInvoicesData({
      filterSearch: filterSearch,
      searchText: searchText,
      status: status,
      authUser: authUser,
    }),
    {
      limit: 20,
    },
  );

  const handleEdit = row => {
    setTypeAction('Edit');
    setSelectedInvoice(row);
    setOpenAction(true);
  };

  const handleViewDetail = row => {
    setTypeAction('Detail');
    setSelectedInvoice(row);
    setOpenAction(true);
  };

  const handlePrint = row => {
    setSelectedInvoice(row);
    setOpenDetail(true);
  };

  // const handleShowBy = type => {
  //   setShowBy(type);
  // };

  const fetchCustomers = () => {
    dispatch(DatabaseService.getAllCustomers());
  };

  // const handleChangeCustomer = (e, v) => {
  //     setCustomerId(v ? v.name : null);
  // };

  const handleChangeStatus = (event, value) => {
    if (value?.name) setStatus(value.name);
  };

  const checkPermission = status => {
    switch (authUser.type) {
      case 'frontadmin':
        if (status === 'Created' || status === 'Printed') {
          return true;
        }
        break;
      case 'accountant':
        if (status === 'Uploaded' || status === 'Checking') {
          return true;
        }
        break;
      case 'delivery':
        if (status === 'Uploaded' || status === 'Checking') {
          return true;
        }
        break;
      // case 'admin':
      //   if (status === 'Created') {
      //     return true;
      //   }
      //   break;
      case 'superadmin':
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (customerId) setFilterData(data.filter(e => e.customer_id === customerId));
    else setFilterData(data);
  }, [customerId]);

  useEffect(() => {
    // if (showBy === 'container')
    //     fecthData();

    // fetchDrivers();
    fetchCustomers();

    dispatch(fetchFilter([]));
  }, []);

  useEffect(() => {
    dispatch(fetchSearch(''));
    dispatch(
      fetchFilter([
        { key: 'invoice_no', name: 'Invoice No.' },
        { key: 'container', name: 'Container' },
        { key: 'customer_id', name: 'Customer' },
        { key: 'calculation_type_upper', name: 'Calculation Type' },
        { key: 'warehouse', name: 'Warehouse' },
      ]),
    );
  }, []);

  // useEffect(() => {
  //     items.map((item) => {
  //         let total = item.productNumberList.reduce((sum, p) => sum + p.total, 0);
  //         item.grandTotal = parseFloat(total).toFixed(2);
  //     });

  //     setList(items)
  // }, [items]);

  return (
    <GridContainer>
      <Grid item xs={4} container justifyContent="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.bill" />
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Autocomplete
          closeIcon={false}
          size="small"
          options={authUser.type === 'delivery' ? STATUSDELIVERY : STATUS}
          getOptionLabel={option => option.name}
          style={{ width: 200 }}
          value={STATUS.find(v => v.name === status) || {}}
          onChange={handleChangeStatus}
          renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
        />
      </Grid>

      {RBAC({ allowedRoles: ['superadmin', 'frontadmin'] }) && (
        <Grid item xs={4} container justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={() => setOpenCreate(true)}>
            <Add /> Create Bill
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            <DataTable
              noHeader
              title="Update Delivering"
              columns={columns(handlePrint, handleViewDetail, handleEdit, checkPermission)}
              data={items}
              progressPending={isLoading}
              progressComponent={<LinearIndeterminate />}
              customStyles={customStyles}
            />
          </Card>
          <Grid container justifyContent="flex-end" spacing={5} style={{ marginTop: 5 }}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={getPrev} disabled={isStart}>
                <NavigateBefore /> Previous
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="secondary" onClick={getNext} disabled={isEnd}>
                Next <NavigateNext />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {openCreate && <CreateBill open={openCreate} setOpen={setOpenCreate} />}
      {openDetail && (
        <PrintInvoice
          rowSelected={selectedInvoice}
          open={openDetail}
          setOpen={setOpenDetail}
          setOpenDialog={setOpenDetail}
        />
      )}
      {openAction && (
        <Action open={openAction} setOpenAction={setOpenAction} selectedRow={selectedInvoice} typeAction={typeAction} />
      )}
    </GridContainer>
  );
};

export default Bill;

const STATUS = [
  { name: 'All' },
  { name: 'Created' },
  { name: 'Printed' },
  { name: 'Uploaded' },
  { name: 'Checking' },
  { name: 'Checked' },
  { name: 'Completed' },
  { name: 'Cancel' },
];

const STATUSDELIVERY = [{ name: 'All' }, { name: 'Checking' }, { name: 'Checked' }];
