import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import CounterCard from '../../../../@jumbo/components/Common/CounterCard';
import CmtImage from '../../../../@coremat/CmtImage';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import { Add, AssignmentTurnedIn, Edit, Delete, NavigateNext, NavigateBefore, Print, Visibility } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatabaseService from '../../../../services/database';
import memoize from 'memoize-one';
import { firestore } from '../../../../firebase';
import Api from 'services/api.service';
import { usePagination } from 'use-pagination-firestore';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';
import NumberWithCommas from '../../../../helpers/NumberWithCommas';
import Action from './action';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = memoize(handleViewDetail => [
  {
    cell: row => <Visibility onClick={() => handleViewDetail(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Invoice No.',
    selector: 'invoice_no',
    sortable: true,
    width: '170px',
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'Invoice Date',
    selector: 'invoice_date',
    sortable: true,
    width: '125px',
    cell: row => moment(row.invoice_date.toDate()).format('DD/MM/YYYY'),
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
    width: '360px',
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Calculation Type',
    selector: 'calculation_type',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Warehouse',
    selector: 'warehouse',
    sortable: true,
    width: '120px',
  },
  {
    name: 'Grand Total',
    selector: 'grandTotal',
    sortable: true,
    width: '130px',
    cell: row => NumberWithCommas(row.total.toFixed(2)),
  },
  {
    name: 'Delivery Date',
    selector: 'delivery_date',
    sortable: true,
    width: '200px',
    cell: row => moment(row.delivery_date.toDate()).format('DD/MM/YYYY'),
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderRadius: '50%',
    height: 66,
    minWidth: 66,
    width: 66,
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const CollectionDaily = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { customers } = useSelector(({ master }) => master);
  const { filterSearch } = useSelector(({ search }) => search);
  const { searchText } = useSelector(({ search }) => search);
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [typeAction, setTypeAction] = useState();
  const [showBy, setShowBy] = useState('container');
  const [status, setStatus] = useState('All');
  const [drivers, setDrivers] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const [itemsSum, setItemsSum] = useState(0);
  const lastMidnight = new Date().setHours(0, 0, 0, 0);
  const toDay = new Date(lastMidnight);
  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    DatabaseService.fetchCollectionDailyReport({
      filterSearch: filterSearch,
      searchText: searchText,
      day: toDay,
    }),
    {
      limit: 20,
    },
  );

  const handleViewDetail = row => {
    setTypeAction('Detail');
    setSelectedInvoice(row);
    setOpenAction(true);
  };

  useEffect(() => {
    let itemSum = DatabaseService.fetchSumCollectionDailyReport({
      filterSearch: filterSearch,
      searchText: searchText,
      day: toDay,
    });
    let itemCount = DatabaseService.fetchCountCollectionDailyReport({
      filterSearch: filterSearch,
      searchText: searchText,
      day: toDay,
    });
    setItemsCount(itemCount);
    setItemsSum(itemSum);
    dispatch(fetchFilter([]));
    dispatch(fetchSearch(''));
  }, [dispatch]);

  return (
    <GridContainer>
      <Grid item xs={12} container justifyContent="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.collectionDailyReport" />
        </Typography>
      </Grid>

      {isLoading ? (
        <LinearIndeterminate />
      ) : (
        <>
          <Grid item xs={6}>
            <CounterCard
              icon={
                <Box className={classes.iconWrapper} style={{ borderColor: '#64C9CF' }}>
                  <CmtImage width={30} src={'/images/icons/invoices.png'} alt="..." />
                </Box>
              }
              number={NumberWithCommas(items.length)}
              numberProps={{
                color: 'text.primary',
                fontSize: { xs: 20, xl: 22 },
                fontWeight: 'bold',
              }}
              label={items.length > 1 ? 'Invoices' : 'Invoice'}
              labelProps={{
                color: 'text.secondary',
                fontSize: 12,
                fontWeight: 'normal',
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <CounterCard
              icon={
                <Box className={classes.iconWrapper} style={{ borderColor: '#FFF338' }}>
                  <CmtImage width={30} src={'/images/icons/dollar.png'} alt="..." />
                </Box>
              }
              number={NumberWithCommas(items.reduce((sum, p) => sum + p.total, 0).toFixed(2))}
              numberProps={{
                color: 'text.primary',
                fontSize: { xs: 20, xl: 22 },
                fontWeight: 'bold',
              }}
              label={'Grand Total'}
              labelProps={{
                color: 'text.secondary',
                fontSize: 12,
                fontWeight: 'normal',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Card className={classes.card}>
                <DataTable
                  noHeader
                  title="Update Delivering"
                  columns={columns(handleViewDetail)}
                  data={items.sort((a, b) => (b.invoice_no > a.invoice_no ? 1 : -1))}
                  // progressPending={isLoading}
                  progressComponent={<LinearIndeterminate />}
                  customStyles={customStyles}
                />
              </Card>
              <Grid container justifyContent="flex-end" spacing={5} style={{ marginTop: 5 }}>
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={getPrev} disabled={isStart || items.length === 0}>
                    <NavigateBefore /> Previous
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="contained" color="secondary" onClick={getNext} disabled={isEnd}>
                    Next <NavigateNext />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </>
      )}
      {openAction && (
        <Action open={openAction} setOpenAction={setOpenAction} selectedRow={selectedInvoice} typeAction={typeAction} />
      )}
    </GridContainer>
  );
};

export default CollectionDaily;
