const deliveryStatus = [
  { name: 'Created' },
  { name: 'Printed' },
  { name: 'Delivering' },
  { name: 'Delivered' },
  { name: 'Confirm Delivered' },
  { name: 'Cancel' },
];

export default deliveryStatus;
