import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';

import DragNdrop from '../Components/dragNdrop';
import UploadStatus from '../Components/uploadStatus';
import AlertTable from '../Components/alertTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import AlertUploadImageTable from '../Components/alertUploadImageTable';
import { fetchFilter } from 'redux/actions/Search';
import { NotificationManager } from 'react-notifications';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Import Closed Container', isActive: true },
];

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const UploadImages = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const [uploadted, setUploadted] = useState(false);
  const [warningDatatable, setWarningDatatable] = useState([]);
  const [alert, setAlert] = useState([]);
  const [result, setResult] = useState({});

  const { getRootProps, getInputProps, open, acceptedFiles, inputRef } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png',
    multiple: false,
  });

  const handleUploadFiles = async () => {
    setPending(true);

    await DatabaseService.uploadImages(acceptedFiles)
      .then(res => {
        setResult(res.result);
        setAlert(res.alerts);
        setPending(false);
        setUploadted(true);
        acceptedFiles.splice(0, acceptedFiles.length);
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  useEffect(() => {
    dispatch(fetchFilter([]));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={4} container justifyContent="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.uploadImages" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          Instructure: File name must be match with Product Number such as "C9073583.jpg", you can refer to menu Home for
          Item Code for each item.
        </Typography>
        <Box>
          {/* <IntlMessages id="pages.samplePage.description" /> */}
          {uploadted ? (
            <UploadStatus setUploadted={setUploadted} alert={result} type={'image'} />
          ) : (
            <DragNdrop
              handleUploadFiles={handleUploadFiles}
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              pending={pending}
            />
          )}
          {uploadted && alert.length > 0 && !pending && (
            <div style={{ marginTop: 20 }}>
              <AlertUploadImageTable alerts={alert} />
            </div>
          )}
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default UploadImages;
