import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Action from './action';
import Button from '@material-ui/core/Button';
import { Add, AssignmentTurnedIn, Edit, Delete, NavigateNext, NavigateBefore, Visibility } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatabaseService from '../../../../services/database';
import memoize from 'memoize-one';
import { usePagination } from 'use-pagination-firestore';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = memoize((handleDetail, handleEdit, handleDelete) => [
  {
    cell: row => <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row => <Delete onClick={() => handleDelete(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row => <Visibility onClick={() => handleDetail(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Sub',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
  },
  {
    name: 'Address',
    selector: 'company_address',
    sortable: true,
  },
  {
    name: 'Mobile Phone',
    selector: 'mobile',
    sortable: true,
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const SubManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const { authUser } = useSelector(({ auth }) => auth);
  const { searchText } = useSelector(({ search }) => search);
  const { filterSearch } = useSelector(({ search }) => search);
  const [typeAction, setTypeAction] = useState();

  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    DatabaseService.fetchManagementData({
      authUser: authUser,
      collections: 'sub',
      filterSearch: filterSearch,
      searchText: searchText,
    }),
    {
      limit: 20,
    },
  );

  const handleDialog = () => {
    setTypeAction();
    setSelectedUser();
    setOpen(false);
  };

  const handleDetail = row => {
    setTypeAction('detail');
    setSelectedUser(row);
    setOpen(true);
  };

  const handleEdit = row => {
    setTypeAction('edit');
    setSelectedUser(row);
    setOpen(true);
  };

  const handleDelete = row => {
    setTypeAction('delete');
    setSelectedUser(row);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(DatabaseService.getAllCustomers());
    dispatch(fetchSearch(''));
    dispatch(fetchFilter([{ key: 'name', name: 'Name' }]));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={4} container justifyContent="flex-start">
        .
        <Typography variant="h2">
          <IntlMessages id="page.subManagement" />
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>

      <Grid item xs={4} container justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
          <Add /> Create New Sub
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            <DataTable
              noHeader
              title="Sub Management"
              columns={columns(handleDetail, handleEdit, handleDelete)}
              data={items}
              // selectableRows
              progressPending={isLoading}
              progressComponent={<LinearIndeterminate />}
              customStyles={customStyles}
            />
            {open && <Action open={open} handleDialog={handleDialog} selectedUser={selectedUser} typeAction={typeAction} />}
          </Card>
          <Grid container justifyContent="flex-end" spacing={5} style={{ marginTop: 5 }}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={getPrev} disabled={isStart}>
                <NavigateBefore /> Previous
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="secondary" onClick={getNext} disabled={isEnd}>
                Next <NavigateNext />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default SubManagement;
