import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { lighten, makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { db } from '../../../../../firebase';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DatabaseService from '../../../../../services/database';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Api from 'services/api.service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  appBar: {
    position: 'relative',
  },
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
    marginTop: 20,
  },
  paper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  content: {
    padding: '35px 35px 0px 35px',
  },
  label: {
    fontWeight: 'bold',
    margin: 20,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  [`@media print`]: {
    tr: {
      backgroundColor: '#97C1E0 !important',
    },
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  linear: {
    margin: '25px 0',
  },
  title: {
    marginBottom: 25,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MySwal = withReactContent(Swal);

const defaultValues = {
  delivery_note_no: 'xxxxxxxxxx',
  delivery_note_date: new Date(),
};

const validationSchema = yup.object().shape({
  container: yup.string().required('Container is required'),
  driver: yup.string().required('Driver is required'),
  customer_id: yup
    .array()
    .required('Customer ID is required')
    .nullable(),
  delivery_note_date: yup.date(),

  productNumberList: yup.array().of(
    yup.object().shape({
      po_number: yup.string().required('Item is required'),
      description: yup.string().required('Description is required'),
    }),
  ),
});

const CreateDeliveryNoteByContainer = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { open, setOpen } = props;
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productNumberList',
  });

  const { authUser } = useSelector(({ auth }) => auth);
  // const { customers } = useSelector(({ master }) => master);
  const { drivers } = useSelector(({ master }) => master);
  const [customers, setCustomers] = useState([]);
  const [pending, setPending] = useState(false);
  // const [customerInfo, setCustomerInfo] = useState();
  // const [customerId, setCustomerId] = useState();
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [containerId, setContainerId] = useState();
  const [containerList, setContainerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedIssued, setCheckedIssued] = useState(false);

  // const handleClose = () => {
  //     setOpen(false);
  // };

  const fetchContainer = () => {
    const ordersCollection = collection(db, 'orders');
    const q = query(ordersCollection, where('status', '!=', 'WH China'));
    onSnapshot(q, querySnapshot => {
      const tempDataArray = [];
      querySnapshot.forEach(doc => {
        if (doc.exists()) {
          const data = doc.data();
          data.id = doc.id;
          // Uncomment if needed:
          // if (!data.created_delivery_note_date) {
          //   data.checked = true;
          // }
          const existingObj = tempDataArray.find(o => o.container === data.container);
          if (!existingObj) {
            tempDataArray.push(data);
          }
        }
      });
      setContainerList(tempDataArray);
    });
  };

  const fetchPoNumberList = async () => {
    remove();
    setValue('customer_id', '');
    setSelectCustomers([]);

    const ordersCollection = collection(db, 'orders');
    const q = query(ordersCollection, where('container', '==', containerId));

    try {
      const querySnapshot = await getDocs(q);
      let tempDataArray = [];

      querySnapshot.forEach(doc => {
        if (doc.exists()) {
          let data = doc.data();
          data.id = doc.id;

          if (!data.created_delivery_note_date) {
            data.checked = true;
          }

          let obj = tempDataArray.find(o => o.productNumber === data.po_number);
          if (!obj) {
            tempDataArray.push(data);
          }
        }
      });

      const customers = tempDataArray.map(item => item['customer_id']);
      const uniqueCustomer = [...new Set(customers)];

      const customerPromises = uniqueCustomer.map(async item => {
        const subCollection = collection(db, 'sub');
        const subQuery = query(subCollection, where('customer_id', '==', item.trim()));
        const subQuerySnapshot = await getDocs(subQuery);

        subQuerySnapshot.forEach(doc => {
          if (doc.exists()) {
            let data = doc.data();

            const checkInList = isInArray(data.name, tempDataArray);
            if (checkInList) {
              setCustomers(oldArray => [...oldArray, `${item} -> ${data.name}`]);
            }
          }
        });
      });

      await Promise.all(customerPromises);

      setCustomers(uniqueCustomer);

      setProductList(tempDataArray.sort((a, b) => (a.customer_id > b.customer_id ? 1 : -1)));
    } catch (error) {
      // console.error('Error fetching PO number list:', error);
    }
  };

  const appendProductList = async () => {
    if (selectCustomers.length !== 0 && customers.length === selectCustomers.length) setChecked(true);
    else setChecked(false);

    await remove();
    setPending(true);

    let filterProductList = [];
    let productListOldList = [];

    productList.map(item => {
      selectCustomers.map(item2 => {
        const checkDupPo = productListOldList.includes(item.po_number);
        if (checkDupPo) return;

        const splitSub = item2.split('->')[1]?.trim();

        if (item2 === item.customer_id || splitSub === item.sub_customer.trim()) {
          if (checkedIssued || !item.created_delivery_note_date || typeof item.created_delivery_note_date === 'string') {
            filterProductList.push(item);
            productListOldList.push(item.po_number);
          }
          // if (checkedIssued) {
          //     filterProductList.push(item)
          //     productListOldList.push(item.po_number)
          // } else {
          //     if (!item.created_delivery_note_date || typeof item.created_delivery_note_date === 'string') {
          //         filterProductList.push(item)
          //         productListOldList.push(item.po_number)
          //     }
          // }
        }
      });
    });

    append(filterProductList);

    // if (checkedIssued) {
    //     append(productList.filter(a => selectCustomers.includes(a.customer_id)));
    // } else {
    //     append(productList.filter(a => selectCustomers.includes(a.customer_id) && !a.created_delivery_note_date || typeof a.created_delivery_note_date === 'string'));
    // }

    setPending(false);
  };

  const handleGoback = () => {
    history.push('/billing-delivery');
  };

  const fetchDrivers = () => {
    dispatch(DatabaseService.getAllDriversDispatch());
  };

  // const handleChecked = (e, i) => {
  //     setValue(`productNumberList[${i}].checked`, e.target.checked);
  // };

  const handleCheckedAll = e => {
    setChecked(e.target.checked);

    if (e.target.checked) {
      setSelectCustomers(customers);
      setValue('customer_id', customers);
      clearErrors('customer_id');
    } else {
      setSelectCustomers([]);
      setValue('customer_id', '');
    }
  };

  const handleChecedIssued = e => {
    setCheckedIssued(e.target.checked);
  };

  const onSubmit = async data => {
    if (!checkedIssued) {
      const filterIssued = data.productNumberList.filter(
        x => !x.created_delivery_note_date || typeof x.created_delivery_note_date === 'string',
      );
      data.productNumberList = filterIssued;
    }

    if (data.productNumberList.length > 0) {
      setPending(true);
      Api.addDelivery(data)
        .then(res => {
          window.location = '/billing-delivery';
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else {
      MySwal.fire({
        title: 'Error',
        text: `Item is Required`,
      });
    }
  };

  useEffect(() => {
    fetchContainer();
    fetchDrivers();
  }, []);

  useEffect(() => {
    if (selectCustomers) appendProductList();
  }, [selectCustomers]);

  useEffect(() => {
    appendProductList();
  }, [checkedIssued]);

  useEffect(() => {
    if (containerId) fetchPoNumberList();
  }, [containerId]);

  return (
    // <Dialog open={open} onClose={handleClose} className={classes.dialogRoot} maxWidth={'lg'} fullWidth>
    //     <DialogTitle className={classes.dialogTitleRoot}>
    //         Create New Delivery Note
    //     </DialogTitle>
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <DialogContent dividers> */}
          <h3 className={classes.title}>Create New Delivery Note By Container</h3>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="delivery_note_date"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      {...rest}
                      size="small"
                      label="Delivery Note Date"
                      format="dd/MM/yyyy"
                      variant="inline"
                      inputVariant="outlined"
                      fullWidth
                      error={errors.delivery_note_date && true}
                      helperText={errors.delivery_note_date?.message}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="container"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setContainerId(item?.container);
                      onChange(item?.container);
                    }}
                    value={containerList.find(v => v.container === value) || {}}
                    options={containerList}
                    getOptionLabel={option => option.container?.toString() || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Container"
                        variant="outlined"
                        error={errors.container && true}
                        helperText={errors.container?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="driver"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      onChange(item?.name);
                    }}
                    value={drivers.find(v => v.name === value) || {}}
                    options={drivers}
                    getOptionLabel={option => option.name?.toString() || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Driver"
                        variant="outlined"
                        error={errors.driver && true}
                        helperText={errors.driver?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={9}>
              <Controller
                control={control}
                name="customer_id"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setSelectCustomers(item);
                      // setCustomerId(item);
                      onChange(item);

                      // if (!item) {
                      //     remove();
                      // }
                    }}
                    multiple
                    disableCloseOnSelect
                    value={selectCustomers}
                    options={customers.sort((a, b) => (a > b ? 1 : -1))}
                    getOptionLabel={option => option?.toString() || ''}
                    getOptionSelected={(option, value) => value === option}
                    disabled={!containerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Customer ID"
                        variant="outlined"
                        error={errors.customer_id && true}
                        helperText={errors.customer_id?.message}
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.replace('->', '-')}
                      </React.Fragment>
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox name="checked" />}
                name="checked"
                onChange={e => handleCheckedAll(e)}
                label={'Select All'}
                checked={checked}
                disabled={!containerId}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox name="checked" />}
                name="checked"
                onChange={e => handleChecedIssued(e)}
                label={<p style={{ color: '#F6C276' }}>***Select delivery note issued</p>}
              />
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: '#E6E6E6' }}>
                    <TableRow>
                      <TableCell width={300}>Date</TableCell>
                      <TableCell width={200}>Bill No.</TableCell>
                      <TableCell width={200}>Customer Id</TableCell>
                      <TableCell width={300}>Description</TableCell>
                      <TableCell width={100}>Pack</TableCell>
                      <TableCell width={100}>Weight</TableCell>
                      <TableCell width={100}>Length</TableCell>
                      <TableCell width={100}>Width</TableCell>
                      <TableCell width={100}>Height</TableCell>
                      <TableCell width={150}>CBM</TableCell>
                      <TableCell width={150}>
                        {' '}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={appendProductList}
                          disabled={!selectCustomers.length > 0}>
                          Reload
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.length === 0 ? (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={10}>
                          <div style={{ textAlign: 'center' }}>
                            <span>
                              <div>No Data Available in Table</div>
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      fields.map((field, index) => (
                        <TableRow key={field.id}>
                          <TableCell component="th" scope="row">
                            <p>
                              {moment(new Date((field.date.seconds + field.date.nanoseconds * 10 ** -9) * 1000)).format(
                                'DD/MM/YYYY',
                              ) || ''}
                            </p>
                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={moment(new Date((field.date.seconds + field.date.nanoseconds * 10 ** -9) * 1000)).format("DD/MM/YYYY") || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {field.created_delivery_note_date && typeof field.created_delivery_note_date !== 'string' && (
                              <p style={{ color: field.created_delivery_note_date && '#F6C276' }}>
                                {' '}
                                {'***Delivery Note issued'} <br />
                              </p>
                            )}
                            <p>{field.po_number || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.po_number || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.customer_id || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.customer_id || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.description || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.description || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.packs || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.packs || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.weight || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.weight || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.length || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.length || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.width || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.width || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.height || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={field.height || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{parseFloat(field.cbm).toFixed(4) || ''}</p>

                            {/* <TextField
                                                            size="small"
                                                            disabled
                                                            defaultValue={parseFloat(field.cbm).toFixed(4) || ''}
                                                            fullWidth
                                                        /> */}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Button variant="contained" onClick={() => remove(index)}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {pending && <LinearProgress color="secondary" className={classes.linear} />}

          <Box display="flex" justifyContent="flex-end" mb={4} mt={4}>
            <Button onClick={handleGoback} disabled={pending}>
              Go Back
            </Button>
            <Box ml={2}>
              <Button type="submit" variant="contained" color="primary" disabled={pending}>
                Create
              </Button>
            </Box>
          </Box>
          {/* </DialogContent> */}
        </form>
      </CardContent>
    </Card>
    // </Dialog >
  );
};

function isInArray(obj, list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].sub_customer === obj) {
      return true;
    }
  }

  return false;
}

export default CreateDeliveryNoteByContainer;
