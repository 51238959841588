import React from 'react';
import { useSelector } from 'react-redux';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import {
  ListAlt,
  Person,
  PersonAdd,
  PermContactCalendar,
  DirectionsBoat,
  FlightLand,
  AllInbox,
  InsertPhoto,
  Comment,
  Receipt,
  LocalShipping,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  const intersection = permissions => {
    return authUser && authUser.type && permissions.includes(authUser.type);
  };

  const navigationMenus = [
    intersection(['customer', 'driver', 'admin', 'superadmin', 'frontadmin', 'delivery', 'accountant']) && {
      name: <IntlMessages id={'sidebar.main'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'page.home'} />,
          icon: <ListAlt />,
          type: 'item',
          link: '/home',
        },
      ],
    },
    intersection(['delivery', 'admin', 'superadmin']) && {
      name: <IntlMessages id={'sidebar.import'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'page.importArrivedWhChina'} />,
          icon: <FlightLand />,
          type: 'item',
          link: '/import-arrived-wh-china',
        },
        {
          name: <IntlMessages id={'page.importClosedContainer'} />,
          icon: <AllInbox />,
          type: 'item',
          link: '/import-closed-container',
        },
        {
          name: <IntlMessages id={'page.uploadImages'} />,
          icon: <InsertPhoto />,
          type: 'item',
          link: '/upload-images',
        },
      ],
    },
    intersection(['delivery', 'frontadmin', 'admin', 'superadmin']) && {
      name: <IntlMessages id={'sidebar.update'} />,
      type: 'section',
      children: [
        intersection(['delivery', 'admin', 'superadmin']) && {
          name: <IntlMessages id={'page.updateArrivedWhThai'} />,
          icon: <DirectionsBoat />,
          type: 'item',
          link: '/update-arrived-wh-thai',
        },
        // {
        //   name: <IntlMessages id={'page.updateDelivering'} />,
        //   icon: <AirportShuttle />,
        //   type: 'item',
        //   link: '/update-delivering',
        // },
        // {
        //   name: <IntlMessages id={'page.updateDelivered'} />,
        //   icon: <MarkunreadMailbox />,
        //   type: 'item',
        //   link: '/update-delivered',
        // },
        intersection(['frontadmin', 'delivery', 'admin', 'superadmin']) && {
          name: <IntlMessages id={'page.updateRemark'} />,
          icon: <Comment />,
          type: 'item',
          link: '/update-remark',
        },
        intersection(['frontadmin', 'delivery', 'admin', 'superadmin']) && {
          name: <IntlMessages id={'page.updateRemarked'} />,
          icon: <Comment />,
          type: 'item',
          link: '/update-remarked',
        },
      ],
    },
    intersection(['superadmin', 'frontadmin', 'delivery', 'accountant']) && {
      name: <IntlMessages id={'sidebar.billing'} />,
      type: 'section',
      children: [
        intersection(['superadmin', 'frontadmin', 'accountant', 'delivery']) && {
          name: <IntlMessages id={'page.bill'} />,
          icon: <Receipt />,
          type: 'item',
          link: '/billing-bill',
        },
        intersection(['superadmin', 'delivery']) && {
          name: <IntlMessages id={'page.delivery'} />,
          icon: <LocalShipping />,
          type: 'item',
          link: '/billing-delivery',
        },
      ],
    },
    intersection(['superadmin']) && {
      name: <IntlMessages id={'sidebar.report'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'page.receivableBalance'} />,
          icon: <Receipt />,
          type: 'item',
          link: '/receivable-balance',
        },
        {
          name: <IntlMessages id={'page.collectionDailyReport'} />,
          icon: <Receipt />,
          type: 'item',
          link: '/collection-daily-report',
        },
      ],
    },
    intersection(['delivery', 'admin', 'superadmin']) && {
      name: <IntlMessages id={'sidebar.management'} />,
      type: 'section',
      children: [
        intersection(['admin', 'superadmin']) && {
          name: <IntlMessages id={'page.customerManagement'} />,
          icon: <PermContactCalendar />,
          type: 'item',
          link: '/customer-management',
        },
        intersection(['admin', 'superadmin']) && {
          name: <IntlMessages id={'page.subManagement'} />,
          icon: <PermContactCalendar />,
          type: 'item',
          link: '/sub-management',
        },
        intersection(['delivery', 'admin', 'superadmin']) && {
          name: <IntlMessages id={'page.driverManagement'} />,
          icon: <LocalShipping />,
          type: 'item',
          link: '/driver-management',
        },
        intersection(['admin', 'superadmin']) && {
          name: <IntlMessages id={'page.userManagement'} />,
          icon: <Person />,
          type: 'item',
          link: '/user-management',
        },
        intersection(['admin', 'superadmin']) && {
          name: <IntlMessages id={'page.registrationManagement'} />,
          icon: <PersonAdd />,
          type: 'item',
          link: '/registration-management',
        },
      ],
    },
    intersection(['delivery', 'admin', 'superadmin']) && {
      name: '',
      type: 'section',
      children: [],
    },
    intersection(['delivery', 'admin', 'superadmin']) && {
      name: '',
      type: 'section',
      children: [],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
