import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatabaseService from '../../../../../services/database';
import CardContent from '@material-ui/core/CardContent';
import { Card } from '@material-ui/core';
// import './css/action.css';
import DeliveryNoteDetail from './detail';

const DeliveryNoteNewPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [row, setRow] = useState();
  const [openAction, setOpenAction] = useState(false);

  const fetchData = () => {
    DatabaseService.getDeliveryNoteByNo(id).then(res => {
      setRow(res);
      // setCustomStatus(res.status);
    });
  };

  useEffect(() => {
    fetchData();
    dispatch(DatabaseService.getAllDriversDispatch());
  }, []);

  return (
    <>
      {row && (
        <Card>
          <CardContent>
            <DeliveryNoteDetail
              open={openAction}
              setOpenAction={setOpenAction}
              selectedRow={row}
              typeAction={'Edit'}
              typePage={'newpage'}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default DeliveryNoteNewPage;
